import { useToast } from '@pluralsight/react-ng';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { useUser } from '@ps-fe-common-pages/contexts/UserContext';
import { useAppConfig } from '@ps-fe-common-pages/contexts/AppConfigContext';

import NotificationList from '../../features/notifications/components/notification-list/notification-list';
import { fetchPlan } from '../../features/plans/state/slice';
import ProfileContainer from '../../features/profile/components/profile-container/profile-container';
import SettingsContainer from '../../features/settings/components/settings-container/settings-container';
import { selectToast } from '../../features/toast/state/selectors';
import { selectUser } from '../../features/user/state/selectors';
import { fetchUser } from '../../features/user/state/slice';

const App = () => {
  const { show } = useToast();
  const dispatch = useDispatch();
  const toast = useSelector(selectToast);
  const { user: appUser } = useUser();
  const user = useSelector(selectUser);

  const appConfig = useAppConfig();

  const ROUTES = appConfig.COMMON.ROUTES;

  useEffect(() => {
    if (appUser) {
      dispatch(fetchUser(appUser.userId));
    }
  }, [appUser]);

  useEffect(() => {
    if (toast) {
      show({
        heading: toast.heading,
        palette: toast.palette,
        text: toast.text,
        onClose: () => {},
      });
    }
  }, [show, toast]);

  useEffect(() => {
    const planId = user?.permissionScope[0]?.plans[0]?.planId;

    if (planId) {
      dispatch(fetchPlan({ id: planId }));
    }
  }, [user, dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROUTES.NOTIFICATIONS} element={<NotificationList />} />
        <Route path={ROUTES.PROFILE} element={<ProfileContainer />} />
        <Route path={ROUTES.SETTINGS} element={<SettingsContainer />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
