// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-item {
  align-items: flex-start;
  width: 100%;
  border-radius: var(--pando-radii-lg-border-radius);
  margin: var(--pando-spacing-5) 0;
  padding: var(--pando-spacing-3) 0;
  cursor: pointer;
}
.notification-item:hover {
  background-color: var(--pando-inky-blue-action-background-100-hover-dark);
}
.notification-item__avatar {
  align-self: center;
}
.notification-item__avatar__img {
  padding: var(--pando-spacing-3);
}
.notification-item__body {
  flex-direction: column;
  margin-left: var(--pando-spacing-3);
  align-self: center;
}
.notification-item__body__title {
  font-size: var(--pando-font-sizes-xl);
  color: var(--pando-inky-blue-neutral-text-initial-dark);
}
.notification-item__body__time-interval {
  font-size: var(--pando-font-sizes-md);
  color: var(--pando-inky-blue-neutral-text-100-dark);
}
.notification-item__actions {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/features/notifications/components/notification/notification.scss"],"names":[],"mappings":"AACA;EACE,uBAAA;EACA,WAAA;EACA,kDAAA;EACA,gCAAA;EACA,iCAAA;EACA,eAAA;AAAF;AAEE;EACE,yEAAA;AAAJ;AAGE;EACE,kBAAA;AADJ;AAGI;EACE,+BAAA;AADN;AAKE;EACE,sBAAA;EACA,mCAAA;EACA,kBAAA;AAHJ;AAKI;EACE,qCAAA;EACA,uDAAA;AAHN;AAMI;EACE,qCAAA;EACA,mDAAA;AAJN;AAQE;EACE,eAAA;AANJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.notification-item {\n  align-items: flex-start;\n  width: 100%;\n  border-radius: var(--pando-radii-lg-border-radius);\n  margin: var(--pando-spacing-5) 0;\n  padding: var(--pando-spacing-3) 0;\n  cursor: pointer;\n\n  &:hover {\n    background-color: var(--pando-inky-blue-action-background-100-hover-dark);\n  }\n\n  &__avatar {\n    align-self: center;\n\n    &__img {\n      padding: var(--pando-spacing-3);\n    }\n  }\n\n  &__body {\n    flex-direction: column;\n    margin-left: var(--pando-spacing-3);\n    align-self: center;\n\n    &__title {\n      font-size: var(--pando-font-sizes-xl);\n      color: var(--pando-inky-blue-neutral-text-initial-dark);\n    }\n\n    &__time-interval {\n      font-size: var(--pando-font-sizes-md);\n      color: var(--pando-inky-blue-neutral-text-100-dark);\n    }\n  }\n\n  &__actions {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
