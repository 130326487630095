import { FlexContainer, FlexItem, For, Show } from '@pluralsight/react-ng';
import React, { useEffect, useRef } from 'react';
import './notification-list.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectUser } from '../../../user/state/selectors';
import { NotificationStatus } from '../../state/interfaces';
import {
  selectNotificationsError,
  selectNotificationsList,
} from '../../state/selectors';
import {
  deleteNotification,
  fetchNotifications,
  readNotifications,
} from '../../state/slice';
import Notifications from '../notification/notification';

import type { Notification } from '../../state/interfaces';
import type { AppDispatch } from '../../../../store';

const NotificationList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();

  const user = useSelector(selectUser);
  const notifications = useSelector(selectNotificationsList);
  const error = useSelector(selectNotificationsError);
  const prevUserRef = useRef(user);

  useEffect(() => {
    if (prevUserRef.current !== user) {
      prevUserRef.current = user;
      dispatch(fetchNotifications());
    }
  }, [user, dispatch]);

  const handleReadNotifications = (notification: Notification) => {
    dispatch(readNotifications([notification]));
    dispatch(fetchNotifications());
  };

  const handleDeleteNotification = (notificationId: string) => {
    dispatch(deleteNotification(notificationId));
    dispatch(fetchNotifications());
  };

  return (
    <FlexContainer className="notification-list__container">
      <FlexItem className="notification-list__header">
        <h4 className="notification-list__header__title">
          {t('notifications.notificationsLabel')}
        </h4>
      </FlexItem>
      <FlexContainer className="notification-list__body">
        <For
          each={notifications ?? []}
          fallback={
            <Show
              when={!!error}
              fallback={
                <p className="notification-list__body--empty">
                  {t('notifications.noNotificationsLabel')}
                </p>
              }
            >
              <p className="notification-list__body---error">
                Error:{' '}
                {typeof error === 'string'
                  ? error
                  : (error?.message ?? t('messages.unknownError'))}
              </p>
            </Show>
          }
          children={(notification) => (
            <Show
              when={notification.status !== NotificationStatus.read}
              fallback={<></>}
            >
              <Notifications
                key={notification.id}
                notification={notification}
                readNotification={handleReadNotifications}
                deleteNotification={handleDeleteNotification}
              />
            </Show>
          )}
        />
      </FlexContainer>
    </FlexContainer>
  );
};

export default NotificationList;
