import React from 'react';
import './skills-widget.scss';
import { FlexContainer, Badge, FlexItem } from '@pluralsight/react-ng';
import { SkillIqIcon } from '@pluralsight/icons';
import { useTranslation } from 'react-i18next';

import LoadingIcon from '../../../../assets/loading.svg';
import { SkillStatus } from '../../state/interfaces';
import { formatDate } from '../../../../shared/utils';

import type { Skill } from '../../state/interfaces';

interface SkillsWidgetProps {
  skill: Skill;
}

const SkillsWidget: React.FC<SkillsWidgetProps> = ({ skill }) => {
  const { i18n, t } = useTranslation();

  const renderTestResults = () => {
    if (!skill.testResults) return null;

    return (
      <FlexContainer
        className="skills-widget__content__test-results"
        direction="col"
      >
        <FlexContainer className="skills-widget__content__test-results__info">
          <SkillIqIcon
            className="skills-widget__content__test-results__icon"
            aria-hidden="true"
          />
          <p>{`${skill.testResults.category} ${skill.testResults.result}`}</p>
        </FlexContainer>
        <FlexContainer className="skills-widget__content__test-results__percentile">
          <p>{`${skill.testResults.percentile} ${t('profile.skillsWidget.percentile')}`}</p>
        </FlexContainer>
      </FlexContainer>
    );
  };

  const renderStatusBadge = () => {
    switch (skill.status) {
      case SkillStatus.IN_PROGRESS:
        return (
          <FlexContainer className="skills-widget__content__status__info">
            <FlexItem className="skills-widget__content__status__icon">
              <img src={LoadingIcon} aria-hidden="true" />
            </FlexItem>
            <p>{t('profile.skillsWidget.inProgress')}</p>
          </FlexContainer>
        );

      case SkillStatus.BETA:
      case SkillStatus.LIVE:
        return (
          <Badge
            className={`skills-widget__content__status__badge skills-widget__content__status__badge--${
              skill.status === SkillStatus.BETA ? 'warning' : 'success'
            }`}
          >
            {skill.status}
          </Badge>
        );

      default:
        return null;
    }
  };

  const renderLastUpdated = () => {
    const statusLabel =
      skill.status === SkillStatus.COMPLETED
        ? t('profile.skillsWidget.completed')
        : t('profile.skillsWidget.lastProgress');

    return (
      <p>
        {`${statusLabel} ${
          skill.lastUpdated
            ? formatDate(
                t('dateFormat.short'),
                skill.lastUpdated,
                i18n.language,
              )
            : ''
        }`}
      </p>
    );
  };

  return (
    <FlexContainer
      className="skills-widget"
      direction="col"
      tabIndex={0}
      onClick={() => window.open(skill.url)}
      aria-label={skill.name}
    >
      <FlexContainer className="skills-widget__image">
        <img src={skill.iconUrl} role="presentation" alt="" />
      </FlexContainer>
      <FlexContainer className="skills-widget__title">
        <h2>{skill.name}</h2>
      </FlexContainer>
      <FlexContainer className="skills-widget__content" direction="col">
        {renderTestResults()}
        <FlexContainer
          className="skills-widget__content__status"
          direction="col"
        >
          {renderStatusBadge()}
          {renderLastUpdated()}
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default SkillsWidget;
