// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-list__container {
  flex-direction: column;
  padding: var(--pando-spacing-6, 1rem);
}
.notification-list__header__title {
  font-size: var(--pando-font-sizes-4xl);
}
.notification-list__body {
  flex-direction: column;
  margin: var(--pando-spacing-5) 0;
}
.notification-list__body--loading, .notification-list__body--empty, .notification-list__body--error {
  font-size: var(--pando-font-sizes-xl);
}`, "",{"version":3,"sources":["webpack://./src/features/notifications/components/notification-list/notification-list.scss"],"names":[],"mappings":"AAEE;EACE,sBAAA;EACA,qCAAA;AADJ;AAKI;EACE,sCAAA;AAHN;AAOE;EACE,sBAAA;EACA,gCAAA;AALJ;AAOI;EAGE,qCAAA;AAPN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.notification-list {\n  &__container {\n    flex-direction: column;\n    padding: var(--pando-spacing-6, 1rem);\n  }\n\n  &__header {\n    &__title {\n      font-size: var(--pando-font-sizes-4xl);\n    }\n  }\n\n  &__body {\n    flex-direction: column;\n    margin: var(--pando-spacing-5) 0;\n\n    &--loading,\n    &--empty,\n    &--error {\n      font-size: var(--pando-font-sizes-xl);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
