import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';

import notificationsReducer from '../features/notifications/state/slice';
import planReducer from '../features/plans/state/slice';
import userProfileInfoReducer, {
  postCertificationFile,
  postUserCertification,
  postUserCertificationSuccess,
} from '../features/profile/state/slice';
import toastReducer from '../features/toast/state/slice';
import userReducer from '../features/user/state/slice';

import rootSaga from './sagas';

import type { UserState } from '../features/user/state/interfaces';
import type { ToastState } from '../features/toast/state/interfaces';
import type { UserProfileInfoState } from '../features/profile/state/interfaces';
import type { IPlanState } from '../features/plans/state/interfaces';
import type { NotificationsState } from '../features/notifications/state/interfaces';
import type { AxiosInstance } from 'axios';
import type { EnhancedStore } from '@reduxjs/toolkit';

export const sagaMiddleware = createSagaMiddleware({
  context: {
    httpClient: null as AxiosInstance | null,
  },
});

type TStoreReducers = {
  notifications: NotificationsState;
  plan: IPlanState;
  toast: ToastState;
  user: UserState;
  userProfileInfo: UserProfileInfoState;
};

const rootReducer = combineReducers({
  notifications: notificationsReducer,
  plan: planReducer,
  toast: toastReducer,
  user: userReducer,
  userProfileInfo: userProfileInfoReducer,
});

const persistedReducer = persistReducer(
  {
    key: 'common',
    storage,
    timeout: undefined,
  },
  rootReducer,
);

const store: EnhancedStore<Partial<TStoreReducers>> = configureStore({
  devTools: {
    name: 'Common pages Store',
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          postCertificationFile.type,
          postUserCertification.type,
          postUserCertificationSuccess.type,
          'persist/PERSIST',
          'persist/REHYDRATE',
        ],
      },
      thunk: false,
    }).concat(sagaMiddleware),
  reducer: persistedReducer,
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof rootReducer>;
export type AppState = ReturnType<typeof store.dispatch>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

export default store;
