// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../../../../assets/dotted-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skills-placeholder {
  display: flex;
  background-color: var(--pando-colors-neutral-surface-200);
  padding: var(--pando-spacing-8) var(--pando-spacing-4);
  align-items: center;
  position: relative;
}
.skills-placeholder__icon {
  width: 48px;
  height: 48px;
}
.skills-placeholder::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.4;
}
.skills-placeholder__info {
  align-items: center;
  position: relative;
}
.skills-placeholder__info__title {
  font-family: var(--pando-fonts-title);
  font-size: var(--pando-font-sizes-md);
  font-weight: var(--pando-font-weights-semibold);
}
.skills-placeholder__info__message {
  font-size: var(--pando-font-sizes-xs);
  font-weight: var(--pando-font-weights-regular);
  text-align: center;
}
.skills-placeholder__button {
  justify-content: center;
  margin-top: var(--pando-spacing-4);
}
.skills-placeholder__button button {
  width: 400px;
  height: 32px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/skills-placeholder/skills-placeholder.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,yDAAA;EACA,sDAAA;EACA,mBAAA;EACA,kBAAA;AAAF;AAEE;EACE,WAAA;EACA,YAAA;AAAJ;AAGE;EACE,WAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,wBAAA;EACA,2BAAA;EACA,4BAAA;EACA,YAAA;AADJ;AAIE;EACE,mBAAA;EACA,kBAAA;AAFJ;AAII;EACE,qCAAA;EACA,qCAAA;EACA,+CAAA;AAFN;AAKI;EACE,qCAAA;EACA,8CAAA;EACA,kBAAA;AAHN;AAOE;EACE,uBAAA;EACA,kCAAA;AALJ;AAOI;EACE,YAAA;EACA,YAAA;EACA,eAAA;AALN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.skills-placeholder {\n  display: flex;\n  background-color: var(--pando-colors-neutral-surface-200);\n  padding: var(--pando-spacing-8) var(--pando-spacing-4);\n  align-items: center;\n  position: relative;\n\n  &__icon {\n    width: 48px;\n    height: 48px;\n  }\n\n  &::before {\n    content: '';\n    position: absolute;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    background-image: url('./../../../../assets/dotted-background.png');\n    background-size: contain;\n    background-position: center;\n    background-repeat: no-repeat;\n    opacity: 0.4;\n  }\n\n  &__info {\n    align-items: center;\n    position: relative;\n\n    &__title {\n      font-family: var(--pando-fonts-title);\n      font-size: var(--pando-font-sizes-md);\n      font-weight: var(--pando-font-weights-semibold);\n    }\n\n    &__message {\n      font-size: var(--pando-font-sizes-xs);\n      font-weight: var(--pando-font-weights-regular);\n      text-align: center;\n    }\n  }\n\n  &__button {\n    justify-content: center;\n    margin-top: var(--pando-spacing-4);\n\n    button {\n      width: 400px;\n      height: 32px;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
