import { PencilIcon } from '@pluralsight/icons';
import { Button, FlexContainer, FlexItem } from '@pluralsight/react-ng';
import './certification-item.scss';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { fetchCertificationUrl } from '../../state/slice';

import type { Certification } from '../../state/interfaces';

const CertificationItem = ({
  certification,
  onEdit,
}: {
  certification: Certification;
  onEdit: (certificate: Certification) => void;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const onViewCertificate = () => {
    dispatch(
      fetchCertificationUrl({
        id: certification.id as string,
      }),
    );
  };

  return (
    <FlexContainer className="certification-item" wrap="wrap">
      <FlexItem className="certification-item-body" grow={1}>
        <h3 className="certification-item-body__title">
          {certification.providerName} | {certification.certificationName}
        </h3>
        <p className="certification-item-body__description">
          {t('certifications.certificationsContainer.issuedOn')}:{' '}
          {format(certification.achievedDate, 'MMMM dd, yyy')} •{' '}
          {t('certifications.certificationsContainer.validationCode')}:{' '}
          {certification.validationCode || '--'}
        </p>
        <Button
          className="certification-item-body__btn"
          size="md"
          palette="action"
          usage="outline"
          onClick={onViewCertificate}
          aria-label={t(
            'certifications.certificationsContainer.viewCertificate',
          )}
        >
          {t('certifications.certificationsContainer.viewCertificate')}
        </Button>
      </FlexItem>
      <FlexItem>
        <Button
          className="certification-item__btn"
          size="md"
          palette="action"
          usage="text"
          aria-label={t(
            'certifications.certificationsContainer.editCertificate',
          )}
          startIcon={<PencilIcon />}
          onClick={() => onEdit(certification)}
        >
          {t('certifications.certificationsContainer.edit')}
        </Button>
      </FlexItem>
    </FlexContainer>
  );
};

export default CertificationItem;
