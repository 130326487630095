import { PencilIcon } from '@pluralsight/icons';
import { FlexContainer, FlexItem, IconButton } from '@pluralsight/react-ng';
import React from 'react';
import './settings-card-option.scss';

interface SettingsCardOptionProps {
  header: string;
  subheader: string;
  value: string;
}

const SettingsCardOption: React.FC<SettingsCardOptionProps> = ({
  header,
  subheader,
  value,
}) => {
  return (
    <FlexContainer className="settings-card-option">
      <FlexContainer direction="col" justify="spaceBetween">
        <FlexItem>
          <h3>{header}</h3>
        </FlexItem>
        <FlexItem className="settings-card-option__subheader">
          {subheader}
        </FlexItem>
      </FlexContainer>
      <FlexContainer align="center" justify="flexEnd">
        <FlexItem className="settings-card-option__value">{value}</FlexItem>
        <IconButton
          className="settings-card-option__icon"
          palette="action"
          usage="text"
          size="sm"
          ariaLabel={`Edit email ${value}`}
          disabled
        >
          <PencilIcon />
        </IconButton>
      </FlexContainer>
    </FlexContainer>
  );
};

export default SettingsCardOption;
