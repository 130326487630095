import React, { createContext, useContext, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import {
  useCustomEvent,
  CUSTOM_EVENTS,
} from '@ps-fe-common-pages/hooks/use-custom-event';
import { getAppUserData } from '@ps-fe-common-pages/api/services/user-service';

import type { UserAppData } from '@ps-fe-common-pages/types';
import type { FC, PropsWithChildren } from 'react';

interface ProviderProps {
  initialUser: UserAppData | null;
}

interface UserContextValue {
  user: UserAppData | null;
  setUser: (data: UserAppData) => void;
  refetch: () => Promise<unknown>;
}

const UserContext = createContext<UserContextValue | undefined>(undefined);

export const UserProvider: FC<PropsWithChildren<ProviderProps>> = ({
  initialUser,
  children,
}) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (initialUser) {
      sessionStorage.setItem('userLoggedIn', JSON.stringify(initialUser));
    }
  }, []);

  const { data: user, refetch } = useQuery({
    queryKey: ['user'],
    queryFn: getAppUserData,
    initialData: initialUser,
    enabled: !initialUser,
  });

  const { sendCustomEvent } = useCustomEvent<UserAppData>({
    event: CUSTOM_EVENTS.USER_UPDATED,
    callback: (data) => {
      queryClient.setQueryData(['user'], data);
      sessionStorage.setItem('userLoggedIn', JSON.stringify(data));
    },
  });

  const setUser = (data: UserAppData) => {
    queryClient.setQueryData(['user'], data);
    sessionStorage.setItem('userLoggedIn', JSON.stringify(data));
    sendCustomEvent(CUSTOM_EVENTS.USER_UPDATED, data);
  };

  // TODO: Loader should be implemented.

  return (
    <UserContext.Provider value={{ user, setUser, refetch }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextValue => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
};
