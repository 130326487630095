import i18n from 'i18next';
import {
  call,
  getContext,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { setToast } from '../../toast/state/slice';
import { selectUser } from '../../user/state/selectors';

import { UserProfileInfoService } from './services';
import {
  deleteCertification,
  deleteCertificationFailure,
  deleteCertificationSuccess,
  fetchUserCertifications,
  fetchUserCertificationsFailure,
  fetchUserCertificationsSuccess,
  fetchUserProfileInfo,
  fetchUserProfileInfoFailure,
  fetchUserProfileInfoSuccess,
  fetchUserSkills,
  fetchUserSkillsFailure,
  fetchUserSkillsSuccess,
  postUserCertification,
  postUserCertificationFailure,
  postUserCertificationSuccess,
  patchUserProfileInfo,
  patchUserProfileInfoFailure,
  patchUserProfileInfoSuccess,
  patchUserCertification,
  patchUserCertificationSuccess,
  patchUserCertificationFailure,
  fetchUploadCertificationUrl,
  fetchUploadCertificationUrlFailure,
  fetchUploadCertificationUrlSuccess,
  postCertificationFile,
  postCertificationFileSuccess,
  postCertificationFileFailure,
  fetchCertificationProviders,
  fetchCertificationProvidersFailure,
  fetchCertificationProvidersSuccess,
  fetchCertificationUrl,
  fetchCertificationUrlFailure,
  fetchCertificationUrlSuccess,
  patchCertificationFile,
} from './slice';
import { SKILLS_MOCK } from './mocks';

import type {
  Certification,
  CertificationUploadUrlResponse,
  Providers,
  UserProfileInfo,
} from './interfaces';
import type { AxiosInstance } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';

function* fetchUserProfileInfoHandler(action: PayloadAction<string>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userProfileInfoService = new UserProfileInfoService(httpClient);
    const psId = action.payload;
    const userProfileInfo: UserProfileInfo = yield call(
      userProfileInfoService.fetchUserProfileInfo,
      psId,
    );

    yield put(fetchUserProfileInfoSuccess(userProfileInfo));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchUserProfileInfoFailure(error.message));
    } else {
      yield put(fetchUserProfileInfoFailure('An unknown error occurred'));
    }
  }
}

function* patchUserProfileInfoHandler(
  action: PayloadAction<Partial<UserProfileInfo>>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userProfileInfoService = new UserProfileInfoService(httpClient);
    const { psId, companyName, jobRole, nickname } = action.payload;

    if (!psId) throw new Error('No psId provided');

    const patchUserProfileInfo: UserProfileInfo = yield call(
      userProfileInfoService.patchUserProfileInfo,
      psId,
      {
        companyName: companyName || null,
        jobRole: jobRole || null,
        nickname,
      },
    );

    yield put(patchUserProfileInfoSuccess(patchUserProfileInfo));
    yield put(
      setToast({
        heading: '',
        palette: 'success',
        text: i18n.t('profile.profileInfoContainer.successMessage'),
      }),
    );
  } catch (error) {
    if (error instanceof Error) {
      yield put(patchUserProfileInfoFailure(error.message));
    } else {
      yield put(patchUserProfileInfoFailure('An unknown error occurred'));
    }

    yield put(
      setToast({
        heading: '',
        palette: 'danger',
        text: i18n.t('profile.profileInfoContainer.errorMessage'),
      }),
    );
  }
}

function* fetchUserSkillsHandler(_action: PayloadAction<string>) {
  try {
    // TODO(ferney-pena): Uncomment when the service is ready
    // const httpClient: AxiosInstance = yield getContext('httpClient');
    // const userProfileInfoService = new UserProfileInfoService(httpClient);
    // const psId = action.payload;
    // const skills: Skill[] = yield call(
    //   userProfileInfoService.fetchUserSkills,
    //   psId,
    // );

    // TODO(ferney-pena): Remove this mock when the service is ready
    const skills = SKILLS_MOCK;

    yield put(fetchUserSkillsSuccess(skills));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchUserSkillsFailure(error.message));
    } else {
      yield put(fetchUserSkillsFailure('An unknown error occurred'));
    }
  }
}

function* fetchUserCertificationsHandler(action: PayloadAction<string>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userProfileInfoService = new UserProfileInfoService(httpClient);

    const certifications: Certification[] = yield call(
      userProfileInfoService.fetchUserCertifications,
      action.payload,
    );

    yield put(fetchUserCertificationsSuccess(certifications));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchUserCertificationsFailure(error.message));
    } else {
      yield put(fetchUserCertificationsFailure('An error occurred'));
    }
  }
}

function* postUserCertificationHandler(
  action: PayloadAction<{ value: Certification }>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userProfileInfoService = new UserProfileInfoService(httpClient);
    const { value } = action.payload;
    const { psUserId } = yield select(selectUser);
    const certificationResponse: { id: string } = yield call(
      userProfileInfoService.postUserCertification,
      value,
      psUserId,
    );

    yield put(
      postUserCertificationSuccess({
        ...value,
        id: certificationResponse.id,
      }),
    );
    yield put(
      postCertificationFile({
        id: certificationResponse.id,
        file: value.certificationFile as File[],
      }),
    );

    yield put(
      fetchUploadCertificationUrl({
        id: certificationResponse.id,
      }),
    );

    yield put(fetchUserCertifications(psUserId));
  } catch (error) {
    if (error instanceof Error) {
      yield put(postUserCertificationFailure(error.message));
    } else {
      yield put(postUserCertificationFailure('An error occurred'));
    }
  }
}

function* deleteCertificationHandler(action: PayloadAction<{ id: string }>) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userProfileInfoService = new UserProfileInfoService(httpClient);
    const { id } = action.payload;
    const { psUserId } = yield select(selectUser);

    yield call(
      userProfileInfoService.deleteUserCertification,
      id as string,
      psUserId,
    );

    yield put(deleteCertificationSuccess({ id }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(deleteCertificationFailure(error.message));
    } else {
      yield put(deleteCertificationFailure('An error occurred'));
    }
  }
}

function* patchUserCertificationHandler(
  action: PayloadAction<{ value: Certification }>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userProfileInfoService = new UserProfileInfoService(httpClient);
    const { value } = action.payload;
    const { psUserId } = yield select(selectUser);
    const certification: Certification = yield call(
      userProfileInfoService.patchUserCertification,
      value,
      psUserId,
    );

    yield put(patchUserCertificationSuccess({ ...value }));

    if (value.certificationFile) {
      yield put(
        patchCertificationFile({
          id: certification.id as string,
          file: value.certificationFile as File[],
        }),
      );

      yield put(
        fetchUploadCertificationUrl({
          id: certification.id as string,
        }),
      );
    }

    yield put(fetchUserCertifications(psUserId));
  } catch (error) {
    if (error instanceof Error) {
      yield put(patchUserCertificationFailure(error.message));
    } else {
      yield put(patchUserCertificationFailure('An error occurred'));
    }
  }
}

function* fetchUploadCertificationUrlHandler(
  action: PayloadAction<{ id: string }>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userProfileInfoService = new UserProfileInfoService(httpClient);
    const { psUserId } = yield select(selectUser);
    const { url }: CertificationUploadUrlResponse = yield call(
      userProfileInfoService.fetchUploadCertificationUrl,
      psUserId,
      action.payload.id,
    );

    yield put(fetchUploadCertificationUrlSuccess({ url }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchUploadCertificationUrlFailure(error.message));
    } else {
      yield put(fetchUploadCertificationUrlFailure('An error occurred'));
    }
  }
}

function* postCertificationFileHandler(
  action: PayloadAction<{ id: string; file: File[] }>,
) {
  try {
    const { payload } = yield take(fetchUploadCertificationUrlSuccess.type);

    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userProfileInfoService = new UserProfileInfoService(httpClient);
    const id: string = yield call(
      userProfileInfoService.postCertificationFile,
      payload.url,
      action.payload.file[0],
    );

    yield put(postCertificationFileSuccess({ id, fileId: id }));
  } catch (error) {
    yield put(deleteCertification({ id: action.payload.id }));

    if (error instanceof Error) {
      yield put(postCertificationFileFailure(error.message));
    } else {
      yield put(postCertificationFileFailure('An error occurred'));
    }
  }
}

function* patchCertificationFileHandler(
  action: PayloadAction<{ id: string; file: File[] }>,
) {
  try {
    const { payload } = yield take(fetchUploadCertificationUrlSuccess.type);

    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userProfileInfoService = new UserProfileInfoService(httpClient);
    const id: string = yield call(
      userProfileInfoService.postCertificationFile,
      payload.url,
      action.payload.file[0],
    );

    yield put(postCertificationFileSuccess({ id, fileId: id }));
  } catch (error) {
    if (error instanceof Error) {
      yield put(postCertificationFileFailure(error.message));
    } else {
      yield put(postCertificationFileFailure('An error occurred'));
    }
  }
}

function* fetchCertificationProvidersHandler() {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userProfileInfoService = new UserProfileInfoService(httpClient);
    const providers: Providers[] = yield call(
      userProfileInfoService.fetchCertificationProviders,
    );

    yield put(fetchCertificationProvidersSuccess(providers));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchCertificationProvidersFailure(error.message));
    } else {
      yield put(fetchCertificationProvidersFailure('An error occurred'));
    }
  }
}

function* fetchViewCertificateUrlHandler(
  action: PayloadAction<{ id: string }>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');
    const userProfileInfoService = new UserProfileInfoService(httpClient);
    const { psUserId: id } = yield select(selectUser);
    const { url }: CertificationUploadUrlResponse = yield call(
      userProfileInfoService.fetchViewCertificateUrl,
      id,
      action.payload.id,
    );

    yield put(fetchCertificationUrlSuccess(url));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchCertificationUrlFailure(error.message));
    } else {
      yield put(fetchCertificationUrlFailure('An error occurred'));
    }
  }
}

function* watchProviderLoadEvent() {
  yield take(fetchCertificationProviders.type);

  yield call(fetchCertificationProvidersHandler);
}

export default function* userProfileInfoSagas() {
  yield takeLatest(fetchUserProfileInfo.type, fetchUserProfileInfoHandler);
  yield takeLatest(fetchUserSkills.type, fetchUserSkillsHandler);
  yield takeLatest(patchUserProfileInfo.type, patchUserProfileInfoHandler);
  yield takeLatest(
    fetchUserCertifications.type,
    fetchUserCertificationsHandler,
  );
  yield takeLatest(postUserCertification.type, postUserCertificationHandler);
  yield takeLatest(deleteCertification.type, deleteCertificationHandler);
  yield takeLatest(patchUserCertification.type, patchUserCertificationHandler);
  yield takeLatest(
    fetchUploadCertificationUrl.type,
    fetchUploadCertificationUrlHandler,
  );
  yield takeLatest(postCertificationFile.type, postCertificationFileHandler);
  yield takeLatest(patchCertificationFile.type, patchCertificationFileHandler);
  yield takeLatest(fetchCertificationUrl, fetchViewCertificateUrlHandler);
  yield call(watchProviderLoadEvent);
}
