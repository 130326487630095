import { createSelector } from 'reselect';

import type { AppState } from '../../../store';
import type { NotificationsState } from './interfaces';

const selectNotificationsState = (state: AppState) => state.notifications;

export const selectNotificationsList = createSelector(
  [selectNotificationsState],
  (notitficationsState: NotificationsState) => notitficationsState.list,
);

export const selectNotificationsLoading = createSelector(
  [selectNotificationsState],
  (notitficationsState: NotificationsState) => notitficationsState.loading,
);

export const selectNotificationsError = createSelector(
  [selectNotificationsState],
  (notitficationsState: NotificationsState) => notitficationsState.error,
);
