import { PencilIcon } from '@pluralsight/icons';
import {
  Avatar,
  Button,
  FlexContainer,
  FlexItem,
  FormControlProvider,
  IconButton,
  Input,
  Label,
  Show,
} from '@pluralsight/react-ng';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { selectUserProfileInfo } from '../../state/selectors';
import { fetchUserProfileInfo, patchUserProfileInfo } from '../../state/slice';
import './profile-info.scss';
import ProfileCard from '../profile-card/profile-card';

const ProfileInfo = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const [psId, setPsId] = useState('');
  const [update, setUpdate] = useState({
    companyName: '',
    jobRole: '',
    nickname: '',
  });

  const userProfileInfo = useSelector(selectUserProfileInfo);

  useEffect(() => {
    // TODO (przem-wierzbicki): Update in scope of NGP-2769
    const storedUser = sessionStorage.getItem('userLoggedIn');

    if (storedUser) {
      const userLoggedIn = JSON.parse(storedUser) as {
        appMetadata: { psId: string };
      };

      setPsId(userLoggedIn.appMetadata.psId);
    }
  }, [psId]);

  useEffect(() => {
    if (psId) {
      dispatch(fetchUserProfileInfo(psId));
    }
  }, [dispatch, psId]);

  useEffect(() => {
    setUpdate({
      companyName: userProfileInfo?.companyName || '',
      jobRole: userProfileInfo?.jobRole || '',
      nickname: userProfileInfo?.nickname || '',
    });
  }, [userProfileInfo]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdate({ ...update, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    dispatch(
      patchUserProfileInfo({
        ...update,
        psId,
        nickname: update.nickname.trim(),
      }),
    );
    setIsEditing(false);
  };

  return (
    <ProfileCard>
      <div className="profile-info">
        <FlexItem>
          <Avatar label={userProfileInfo?.nickname || ''} size="md" />
        </FlexItem>
        <FlexContainer direction="col">
          <Show
            when={isEditing}
            fallback={
              <>
                <h1 className="profile-info__nickname">
                  {userProfileInfo?.nickname}
                </h1>
                <FlexItem className="profile-info__position">
                  <Show
                    when={
                      !!userProfileInfo?.jobRole &&
                      !!userProfileInfo?.companyName
                    }
                    fallback={
                      <>
                        {userProfileInfo?.jobRole ||
                          userProfileInfo?.companyName}
                      </>
                    }
                  >
                    {t('profile.profileInfoContainer.userPosition', {
                      jobRole: userProfileInfo?.jobRole,
                      companyName: userProfileInfo?.companyName,
                    })}
                  </Show>
                </FlexItem>
              </>
            }
          >
            <FormControlProvider>
              <FlexItem className="profile-info__input">
                <Label htmlFor="nickname">
                  {t('profile.profileInfoContainer.displayName')}
                </Label>
                <Input
                  id="nickname"
                  name="nickname"
                  placeholder=""
                  size="md"
                  value={update.nickname}
                  onChange={handleChange}
                />
              </FlexItem>
              <FlexItem className="profile-info__input">
                <Label htmlFor="jobRole">
                  {t('profile.profileInfoContainer.role')}
                </Label>
                <Input
                  id="jobRole"
                  name="jobRole"
                  placeholder=""
                  size="md"
                  value={update.jobRole}
                  onChange={handleChange}
                />
              </FlexItem>
              <FlexItem className="profile-info__input">
                <Label htmlFor="companyName">
                  {t('profile.profileInfoContainer.companyName')}
                </Label>
                <Input
                  id="companyName"
                  name="companyName"
                  placeholder=""
                  size="md"
                  value={update.companyName}
                  onChange={handleChange}
                />
              </FlexItem>
            </FormControlProvider>
            <FlexContainer>
              <Button
                className="personal-details__button"
                size="lg"
                palette="action"
                usage="outline"
                onClick={() => setIsEditing(false)}
                aria-label={t('profile.profileInfoContainer.cancel')}
              >
                {t('profile.profileInfoContainer.cancel')}
              </Button>
              <div className="personal-details__spacing" />
              <Button
                className="personal-details__button"
                size="lg"
                palette="action"
                usage="filled"
                onClick={handleSubmit}
                disabled={!update.nickname.trim()}
                aria-label={t('profile.profileInfoContainer.save')}
              >
                {t('profile.profileInfoContainer.save')}
              </Button>
            </FlexContainer>
          </Show>
        </FlexContainer>
        <FlexContainer align="flexStart" justify="end">
          <FlexContainer align="center" justify="end" gap="4px">
            <Show when={!isEditing}>
              <>
                <IconButton
                  className="profile-info__pencil-icon"
                  ariaLabel={t('profile.profileInfoContainer.editProfileInfo')}
                  size="sm"
                  usage="text"
                  palette="action"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  <PencilIcon />
                </IconButton>
                <FlexItem
                  className="profile-info__edit-text"
                  onClick={() => {
                    setIsEditing(true);
                  }}
                >
                  {t('profile.profileInfoContainer.edit')}
                </FlexItem>
              </>
            </Show>
          </FlexContainer>
        </FlexContainer>
      </div>
    </ProfileCard>
  );
};

export default ProfileInfo;
