import { TrashIcon, UploadIcon } from '@pluralsight/icons';
import {
  Button,
  FieldMessage,
  FileUploadContext,
  FileUploadDropzone,
  FileUploadHiddenInput,
  FileUploadItemGroup,
  FileUploadLabel,
  FileUploadRootProvider,
  FileUploadTrigger,
  FlexContainer,
  FormControlProvider,
  IconButton,
  Show,
} from '@pluralsight/react-ng';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import UploadedItem from './uploaded-item';

import type { FileUploadFileChangeDetails } from '@pluralsight/react-ng';
import type { formStateType } from '../certification-form/certification-form';

interface uploadValueInterface {
  accept: {
    [key: string]: string[];
  };
  maxFiles: number;
  maxFileSize: number;
  acceptedFiles: FileUploadFileChangeDetails;
  rejectedFiles: FileUploadFileChangeDetails;
  onFileChange: () => void;
}

const UploadCertificationField = ({
  objectKey,
  formState,
  fileUpload,
  onFileDelete,
}: {
  objectKey?: string | null;
  formState: Partial<formStateType>;
  fileUpload: {
    name: string;
    value: uploadValueInterface;
  };
  onFileDelete: () => void;
}) => {
  const { t } = useTranslation();
  const [savedKey, setSavedKey] = useState(objectKey);
  const [isEditMode, setIsEditMode] = useState(false);

  const onFileDeleteHandler = () => {
    setIsEditMode(false);
    onFileDelete();
  };

  const onSavedDeleted = () => {
    setSavedKey(null);
    onFileDelete();
  };

  const renderSelectedFile = ({
    acceptedFiles,
    rejectedFiles,
  }: FileUploadFileChangeDetails) => {
    if (acceptedFiles.length) {
      return acceptedFiles.map((file: File) => (
        <UploadedItem file={file} onFileDeleteHandler={onFileDeleteHandler} />
      ));
    } else if (rejectedFiles.length) {
      return (
        <UploadedItem
          file={rejectedFiles[0].file}
          onFileDeleteHandler={onFileDeleteHandler}
          error={rejectedFiles[0].errors[0]}
        />
      );
    }
  };

  return (
    <FlexContainer
      className="certification-container-form__field"
      direction="col"
    >
      <FormControlProvider {...formState.certificationFile}>
        <FileUploadRootProvider
          className="certification-container-form__upload"
          value={fileUpload.value}
        >
          <FileUploadLabel
            className={`certification-container-form__upload__field__label${formState.certificationFile?.invalid ? ' certification-container-form__upload__field__label--error' : ''}`}
          >
            {t('certifications.certificationForm.uploadCertification')}
          </FileUploadLabel>
          <Show
            when={
              !fileUpload.value?.acceptedFiles?.length &&
              !fileUpload.value?.rejectedFiles?.length &&
              !savedKey
            }
          >
            <FileUploadDropzone
              className={`certification-container-form__upload__field${formState.certificationFile?.invalid ? ' certification-container-form__upload__field--error' : ''}`}
            >
              <FileUploadTrigger asChild>
                <Button
                  palette="action"
                  size="md"
                  startIcon={
                    <UploadIcon width={16} height={16} aria-hidden="true" />
                  }
                  aria-label={t('certifications.certificationForm.uploadFile')}
                >
                  {t('certifications.certificationForm.chooseFile')}
                </Button>
              </FileUploadTrigger>
              <FileUploadLabel className="certification-container-form__upload__field__label__drag">
                {t('certifications.certificationForm.dragAndDrop')}
              </FileUploadLabel>
            </FileUploadDropzone>
          </Show>
          <Show when={!!savedKey}>
            <FlexContainer
              className={`certification-container-form__upload__field pando-file-upload__dropzone`}
              justify={'spaceBetween'}
            >
              <span>{savedKey}</span>
              <IconButton
                className="fieldset__description__trash-icon"
                palette="danger"
                size="sm"
                usage="text"
                onClick={onSavedDeleted} // after this action, upload is required
                ariaLabel={t(
                  'certifications.certificationForm.deleteUploadedFile',
                )}
              >
                <TrashIcon />
              </IconButton>
            </FlexContainer>
          </Show>
          <Show when={!savedKey}>
            <FileUploadItemGroup
              className={`certification-container-form__upload__item-group${fileUpload.value.rejectedFiles?.length ? ' certification-container-form__upload__item-group--error' : ''} `}
            >
              <FileUploadContext>{renderSelectedFile}</FileUploadContext>
            </FileUploadItemGroup>
          </Show>

          <FileUploadHiddenInput name="file-up" required={!isEditMode} />
        </FileUploadRootProvider>
        <FieldMessage
          id="achievedDate:invalid"
          className={`ertification-container-form__upload__field__restriction${formState.certificationFile?.invalid ? ' certification-container-form__upload__field__restriction--error' : ''}`}
        >
          {formState.certificationFile?.invalid
            ? t('certifications.certificationForm.uploadRestrictionError')
            : t('certifications.certificationForm.uploadRestriction')}
        </FieldMessage>
      </FormControlProvider>
    </FlexContainer>
  );
};

export default UploadCertificationField;
