import {
  TabContent,
  TabsList,
  TabsRoot,
  TabTrigger,
} from '@pluralsight/react-ng';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { selectUser } from '../../../user/state/selectors';
import PersonalDetails from '../personal-details/personal-details';
import SettingsCardOption from '../settings-card-option/settings-card-option';
import SettingsCard from '../settings-card/settings-card';
import './settings-container.scss';

const SettingsContainer = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const currentRoute = searchParams.get('currentRoute');

  const user = useSelector(selectUser);

  return (
    <div className="settings-container">
      <div className="settings-container__header">
        <h2 className="settings-container__header-title">
          {t('settings.settingsContainer.settingsTitle')}
        </h2>
      </div>
      <div className="settings-container__subheader">
        <span className="settings-container__subheader-span">
          {t('settings.settingsContainer.customizeYourExperience')}
        </span>
      </div>
      <TabsRoot defaultValue={currentRoute ?? 'account'}>
        <TabsList>
          <TabTrigger value="account" id="account-tab" tabIndex={0}>
            {t('settings.settingsContainer.tabAccount')}
          </TabTrigger>
          <TabTrigger value="appearance" id="appearance-tab" tabIndex={0}>
            {t('settings.settingsContainer.tabAppearance')}
          </TabTrigger>
          <TabTrigger value="notifications" id="notifications-tab" tabIndex={0}>
            {t('settings.settingsContainer.tabNotifications')}
          </TabTrigger>
          <TabTrigger value="devices" id="devices-tab" tabIndex={0}>
            {t('settings.settingsContainer.tabDevices')}
          </TabTrigger>
          <TabTrigger value="subscription" id="subscription-tab" tabIndex={0}>
            {t('settings.settingsContainer.tabSubscription')}
          </TabTrigger>
          <TabTrigger value="security" id="security-tab" tabIndex={0}>
            {t('settings.settingsContainer.tabSecurityAndPrivacy')}
          </TabTrigger>
        </TabsList>

        {user && (
          <TabContent aria-labelledby="account-tab" value="account">
            <SettingsCard
              header={t('settings.settingsContainer.signInDetails')}
              subheader={t('settings.settingsContainer.manageTheInformation')}
            >
              <SettingsCardOption
                header={t('settings.settingsContainer.emailAddress')}
                subheader={t('settings.settingsContainer.weSendCommunication')}
                value={user.email}
              />
            </SettingsCard>
            <SettingsCard
              header={t('settings.settingsContainer.personalDetails')}
              subheader={t('settings.settingsContainer.thisIsTheName')}
            >
              <PersonalDetails />
            </SettingsCard>
          </TabContent>
        )}

        <TabContent
          aria-labelledby="appearance-tab"
          value="appearance"
        ></TabContent>
        <TabContent
          aria-labelledby="notifications-tab"
          value="notifications"
        ></TabContent>
        <TabContent aria-labelledby="devices-tab" value="devices"></TabContent>
        <TabContent
          aria-labelledby="subscription-tab"
          value="subscription"
        ></TabContent>
        <TabContent
          aria-labelledby="security-tab"
          value="security"
        ></TabContent>
      </TabsRoot>
    </div>
  );
};

export default SettingsContainer;
