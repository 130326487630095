// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certification-item {
  background-color: var(--pando-colors-neutral-surface-200);
  margin: var(--pando-spacing-2) 0;
  padding: var(--pando-spacing-8) var(--pando-spacing-10);
}
.certification-item-body {
  container-type: inline-size;
}
.certification-item-body__title {
  font-size: var(--pando-font-sizes-lg);
  font-weight: var(--pando-font-weights-semibold);
}
.certification-item-body__description {
  font-size: var(--pando-font-sizes-sm);
  margin: var(--pando-spacing-2) 0 var(--pando-spacing-4);
  color: var(--pando-colors-neutral-text-200);
  line-height: var(--pando-line-heights-normal);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/certification-item/certification-item.scss"],"names":[],"mappings":"AACA;EACE,yDAAA;EACA,gCAAA;EACA,uDAAA;AAAF;AAEE;EACE,2BAAA;AAAJ;AAEI;EACE,qCAAA;EACA,+CAAA;AAAN;AAGI;EACE,qCAAA;EACA,uDAAA;EACA,2CAAA;EACA,6CAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AADN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.certification-item {\n  background-color: var(--pando-colors-neutral-surface-200);\n  margin: var(--pando-spacing-2) 0;\n  padding: var(--pando-spacing-8) var(--pando-spacing-10);\n\n  &-body {\n    container-type: inline-size;\n\n    &__title {\n      font-size: var(--pando-font-sizes-lg);\n      font-weight: var(--pando-font-weights-semibold);\n    }\n\n    &__description {\n      font-size: var(--pando-font-sizes-sm);\n      margin: var(--pando-spacing-2) 0 var(--pando-spacing-4);\n      color: var(--pando-colors-neutral-text-200);\n      line-height: var(--pando-line-heights-normal);\n      overflow: hidden;\n      text-overflow: ellipsis;\n      white-space: nowrap;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
