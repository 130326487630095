export type TranslationFlags = 'en' | 'es' | 'pt' | 'jp' | 'ge' | 'fr';

const relativeTimeMessages: Record<TranslationFlags, Record<string, string>> = {
  en: {
    futureDay: 'In {value} day(s)',
    pastDay: '{value} day(s) ago',
    futureHour: 'In {value} hour(s)',
    pastHour: '{value} hour(s) ago',
    futureMinute: 'In {value} minute(s)',
    pastMinute: '{value} minute(s)',
    now: 'In this minute',
  },
  es: {
    futureDay: 'En {value} día(s)',
    pastDay: 'Hace {value} día(s)',
    futureHour: 'En {value} hora(s)',
    pastHour: 'Hace {value} hora(s)',
    futureMinute: 'En {value} minuto(s)',
    pastMinute: 'Hace {value} minuto(s)',
    now: 'En este minuto',
  },
  pt: {
    futureDay: 'Em {value} dia(s)',
    pastDay: 'Há {value} dia(s)',
    futureHour: 'Em {value} hora(s)',
    pastHour: 'Há {value} hora(s)',
    futureMinute: 'Em {value} minuto(s)',
    pastMinute: 'Há {value} minuto(s)',
    now: 'Neste minuto',
  },
  jp: {
    futureDay: '{value}日後',
    pastDay: '{value}日前',
    futureHour: '{value}時間後',
    pastHour: '{value}時間前',
    futureMinute: '{value}分後',
    pastMinute: '{value}分前',
    now: 'この瞬間',
  },
  ge: {
    futureDay: 'In {value} Tag(en)',
    pastDay: 'Vor {value} Tag(en)',
    futureHour: 'In {value} Stunde(n)',
    pastHour: 'Vor {value} Stunde(n)',
    futureMinute: 'In {value} Minute(n)',
    pastMinute: 'Vor {value} Minute(n)',
    now: 'In dieser Minute',
  },
  fr: {
    futureDay: 'Dans {value} jour(s)',
    pastDay: 'Il y a {value} jour(s)',
    futureHour: 'Dans {value} heure(s)',
    pastHour: 'Il y a {value} heure(s)',
    futureMinute: 'Dans {value} minute(s)',
    pastMinute: 'Il y a {value} minute(s)',
    now: "À l'instant",
  },
};

export const getRelativeTime = (
  dateString: string,
  language: TranslationFlags,
): string => {
  const currentTime = Date.now();
  const inputTime = new Date(dateString).getTime();
  const differenceInMilliseconds = inputTime - currentTime;

  const differenceInMinutes = differenceInMilliseconds / 60000;
  const differenceInHours = differenceInMilliseconds / (60000 * 60);
  const differenceInDays = differenceInMilliseconds / (60000 * 60 * 24);

  const messages = relativeTimeMessages[language];

  if (Math.abs(differenceInDays) >= 1) {
    return differenceInDays > 0
      ? messages.futureDay.replace(
          '{value}',
          Math.floor(differenceInDays).toString(),
        )
      : messages.pastDay.replace(
          '{value}',
          Math.floor(Math.abs(differenceInDays)).toString(),
        );
  } else if (Math.abs(differenceInHours) >= 1) {
    return differenceInHours > 0
      ? messages.futureHour.replace(
          '{value}',
          Math.floor(differenceInHours).toString(),
        )
      : messages.pastHour.replace(
          '{value}',
          Math.floor(Math.abs(differenceInHours)).toString(),
        );
  } else if (Math.abs(differenceInMinutes) >= 1) {
    return differenceInMinutes > 0
      ? messages.futureMinute.replace(
          '{value}',
          Math.floor(differenceInMinutes).toString(),
        )
      : messages.pastMinute.replace(
          '{value}',
          Math.floor(Math.abs(differenceInMinutes)).toString(),
        );
  } else {
    return messages.now;
  }
};

export const formatDate = (
  format: string,
  date?: Date,
  languageCode = 'en',
): string => {
  if (!date) {
    return '';
  }

  const localesMap: Record<string, string> = {
    en: 'en-US',
    es: 'es-ES',
    fr: 'fr-FR',
    ge: 'de-DE',
    jp: 'ja-JP',
    pt: 'pt-BR',
  };

  const locale = localesMap[languageCode] || 'en-US';

  const options: Intl.DateTimeFormatOptions = {};

  if (format.includes('YYYY')) {
    options.year = 'numeric';
  }

  if (format.includes('MMM') || format.includes('MMMM')) {
    options.month = format.includes('MMMM') ? 'long' : 'short';
  }

  if (format.includes('DD') || format.includes('D')) {
    options.day = format.includes('DD') ? '2-digit' : 'numeric';
  }

  return new Intl.DateTimeFormat(locale, options).format(new Date(date));
};
