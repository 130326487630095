import type { AxiosInstance } from 'axios';
import type { IPlan } from './interfaces';

const API_VERSION = '/v1';
const PLANS_PATH = '/plans';

export class PlanService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchPlan = async (planId: string): Promise<IPlan> => {
    try {
      const response = await this.httpClient.get<IPlan>(
        `${API_VERSION}${PLANS_PATH}/${planId}`,
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching Plan:', error);

      throw error;
    }
  };
}
