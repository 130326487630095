import type { AxiosInstance } from 'axios';
import type { Notification, NotificationsMessage } from './interfaces';

const API_VERSION = '/v1';
const USERS_PATH = '/users';

export class NotificationsService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  getNotifications = async (userId: string): Promise<Notification[]> => {
    try {
      const response = await this.httpClient.get<Notification[]>(
        `${API_VERSION}${USERS_PATH}/${userId}/notifications`,
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching notifications:', error);

      throw error;
    }
  };

  readNotifications = async (
    request: Notification[],
    userId: string,
  ): Promise<NotificationsMessage> => {
    try {
      const data = request.map((notification) => ({
        id: notification.id,
        readDate: new Date().toISOString(),
      }));

      const response = await this.httpClient.patch<NotificationsMessage>(
        `${API_VERSION}${USERS_PATH}/${userId}/notifications`,
        data,
      );

      return response.data;
    } catch (error) {
      console.error('Error reading notifications:', error);

      throw error;
    }
  };

  deleteNotification = async (
    notificationId: string,
    userId: string,
  ): Promise<NotificationsMessage> => {
    try {
      const response = await this.httpClient.delete<NotificationsMessage>(
        `${API_VERSION}${USERS_PATH}/${userId}/notifications/${notificationId}`,
      );

      return response.data;
    } catch (error) {
      console.error('Error reading notifications:', error);

      throw error;
    }
  };
}
