import { FlexContainer, FlexItem, Button } from '@pluralsight/react-ng';
import { useTranslation } from 'react-i18next';

import IconPlaceHolder from '../../../../assets/skills-placeholder.svg';

import './skills-placeholder.scss';

const SkillsPlaceHolder = () => {
  const { t } = useTranslation();

  const redirectToSkills = () => {
    window.location.href = 'https://app.pluralsight.com/skilliq/discover';
  };

  return (
    <FlexContainer direction="col" className="skills-placeholder">
      <FlexItem>
        <img
          className="skills-placeholder__icon"
          src={IconPlaceHolder}
          alt=""
          role="decorative"
        />
      </FlexItem>
      <FlexContainer direction="col" className="skills-placeholder__info">
        <h5 className="skills-placeholder__info__title">
          {t('profile.skillsWidget.placeholder.title')}
        </h5>
        <p className="skills-placeholder__info__message">
          {t('profile.skillsWidget.placeholder.message')}
        </p>
        <FlexContainer className="skills-placeholder__button">
          <Button palette="action" size="lg" onClick={redirectToSkills}>
            {t('profile.skillsWidget.button')}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default SkillsPlaceHolder;
