import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  Notification,
  NotificationsMessage,
  NotificationsState,
} from './interfaces';

const INITIAL_STATE: NotificationsState = {
  error: null,
  list: [],
  loading: false,
  readMessage: null,
};

const slice = createSlice({
  name: 'notifications',
  initialState: INITIAL_STATE,
  reducers: {
    fetchNotifications: (state) => {
      state.error = null;
      state.loading = true;
    },
    fetchNotificationsSuccess: (
      state,
      action: PayloadAction<Notification[]>,
    ) => {
      state.list = action.payload;
      state.loading = false;
    },
    fetchNotificationsFailure: (
      state,
      action: PayloadAction<NotificationsMessage | string>,
    ) => {
      state.error =
        (action.payload as NotificationsMessage).error ?? action.payload;
      state.loading = false;
    },
    readNotifications: (state) => {
      state.loading = true;
    },
    readNotificationsSuccess: (
      state,
      action: PayloadAction<NotificationsMessage>,
    ) => {
      state.loading = false;
      state.readMessage = action.payload;
    },
    readNotificationsFailure: (
      state,
      action: PayloadAction<NotificationsMessage | string>,
    ) => {
      state.loading = false;
      state.error =
        (action.payload as NotificationsMessage).error ?? action.payload;
      state.loading = false;
    },
    deleteNotification: (state) => {
      state.loading = true;
    },
    deleteNotificationSuccess: (
      state,
      action: PayloadAction<NotificationsMessage>,
    ) => {
      state.loading = false;
      state.readMessage = action.payload;
    },
    deleteNotificationFailure: (
      state,
      action: PayloadAction<NotificationsMessage | string>,
    ) => {
      state.loading = false;
      state.error =
        (action.payload as NotificationsMessage).error ?? action.payload;
      state.loading = false;
    },
  },
});

export const {
  fetchNotifications,
  fetchNotificationsSuccess,
  fetchNotificationsFailure,
  readNotifications,
  readNotificationsSuccess,
  readNotificationsFailure,
  deleteNotification,
  deleteNotificationSuccess,
  deleteNotificationFailure,
} = slice.actions;

export default slice.reducer;
