import React, { useEffect, useRef } from 'react';
import './options-dropdown.scss';
import { useTranslation } from 'react-i18next';

interface HeaderOptionProps {
  button: React.ReactNode;
  isOpen: boolean;
  options: React.ReactNode;
  setIsOpen: () => void;
}

const OptionsDropdown: React.FC<HeaderOptionProps> = ({
  button,
  isOpen,
  options,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen]);

  return (
    <div
      ref={dropdownRef}
      className={
        isOpen ? 'common-options-dropdown open' : 'common-options-dropdown'
      }
    >
      <button
        aria-haspopup="true"
        aria-expanded={isOpen}
        aria-label={t('common.actionButtons.moreActions')}
        className="options-dropdown-button"
        onClick={setIsOpen}
      >
        {button}
      </button>
      <div className="options-dropdown-pane">{options}</div>
    </div>
  );
};

export default OptionsDropdown;
