import { createSelector } from 'reselect';

import type { AppState } from '../../../store';
import type { User, UserState } from './interfaces';

const selectUserState = (state: AppState) => state.user;

export const selectUser = createSelector(
  [selectUserState],
  (userState: UserState): User | null => userState.item,
);
