import CertificationsTile from '../certification-tile/certifications-tile';
import ProfileInfo from '../profile-info/profile-info';
import SkillsCard from '../skills-card/skills-card';
import './profile-container.scss';

const ProfileContainer = () => {
  return (
    <div className="profile-container">
      <ProfileInfo />
      <SkillsCard />
      <CertificationsTile />
    </div>
  );
};

export default ProfileContainer;
