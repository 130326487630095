// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-container {
  display: flex;
  flex-direction: column;
  padding: var(--pando-spacing-14, 1rem);
}
.settings-container__header {
  align-items: center;
  display: flex;
  height: 44px;
  justify-content: space-between;
  margin-bottom: var(--pando-spacing-1);
}
.settings-container__header-title {
  font-size: var(--pando-font-sizes-4xl);
  font-weight: 600;
}
.settings-container__subheader {
  align-items: center;
  display: flex;
  height: 24px;
  margin-bottom: var(--pando-spacing-8);
}
.settings-container__subheader-span {
  color: var(--pando-colors-neutral-text-200);
  font-size: var(--pando-font-sizes-md);
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/features/settings/components/settings-container/settings-container.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,sCAAA;AAAF;AAEE;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,8BAAA;EACA,qCAAA;AAAJ;AAGE;EACE,sCAAA;EACA,gBAAA;AADJ;AAIE;EACE,mBAAA;EACA,aAAA;EACA,YAAA;EACA,qCAAA;AAFJ;AAKE;EACE,2CAAA;EACA,qCAAA;EACA,gBAAA;AAHJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.settings-container {\n  display: flex;\n  flex-direction: column;\n  padding: var(--pando-spacing-14, 1rem);\n\n  &__header {\n    align-items: center;\n    display: flex;\n    height: 44px;\n    justify-content: space-between;\n    margin-bottom: var(--pando-spacing-1);\n  }\n\n  &__header-title {\n    font-size: var(--pando-font-sizes-4xl);\n    font-weight: 600;\n  }\n\n  &__subheader {\n    align-items: center;\n    display: flex;\n    height: 24px;\n    margin-bottom: var(--pando-spacing-8);\n  }\n\n  &__subheader-span {\n    color: var(--pando-colors-neutral-text-200);\n    font-size: var(--pando-font-sizes-md);\n    font-weight: 500;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
