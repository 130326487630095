import { all } from 'redux-saga/effects';

import notificationsSagas from '../features/notifications/state/sagas';
import userProfileInfoSagas from '../features/profile/state/sagas';
import toastSagas from '../features/toast/state/sagas';
import userSagas from '../features/user/state/sagas';
import planSagas from '../features/plans/state/sagas';

export default function* rootSaga() {
  yield all([
    notificationsSagas(),
    toastSagas(),
    userSagas(),
    userProfileInfoSagas(),
    planSagas(),
  ]);
}
