// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.certifications-container {
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-body, "PS TT Commons");
}
.certifications-container__header {
  margin-bottom: var(--pando-spacing-4);
}
.certifications-container__title {
  font-size: var(--pando-font-sizes-2xl, 24px);
  font-weight: var(--pando-font-weights-semibold);
  line-height: 1.875rem;
}
.certifications-container__add {
  margin-left: var(--pando-spacing-4);
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/certification-tile/certifications-tile.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,qDAAA;AAAF;AAEE;EACE,qCAAA;AAAJ;AAEE;EACE,4CAAA;EACA,+CAAA;EACA,qBAAA;AAAJ;AAGE;EACE,mCAAA;AADJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.certifications-container {\n  display: flex;\n  flex-direction: column;\n  font-family: var(--font-family-body, 'PS TT Commons');\n\n  &__header {\n    margin-bottom: var(--pando-spacing-4);\n  }\n  &__title {\n    font-size: var(--pando-font-sizes-2xl, 24px);\n    font-weight: var(--pando-font-weights-semibold);\n    line-height: 1.875rem;\n  }\n\n  &__add {\n    margin-left: var(--pando-spacing-4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
