import { useCallback } from 'react';
import { parse, format } from 'date-fns';

const useParsedDate = () => {
  const parseToCustomDate = useCallback((date?: string) => {
    if (!date) return '';

    try {
      const parsedDate = parse(date, 'yyyy-MM-dd', new Date());

      return format(parsedDate, 'MM/dd/yyyy');
    } catch (error) {
      console.error('Invalid date format:', error);

      return date;
    }
  }, []);

  const formatToDefaultDate = useCallback((date?: string) => {
    if (!date) return '';

    try {
      const parsedDate = parse(date, 'MM/dd/yyyy', new Date());

      return format(parsedDate, 'yyyy-MM-dd');
    } catch (error) {
      console.error('Invalid date format:', error);

      return date;
    }
  }, []);

  return { parseToCustomDate, formatToDefaultDate };
};

export default useParsedDate;
