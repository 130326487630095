import { PlusIcon } from '@pluralsight/icons';
import { Button, FlexContainer, FlexItem } from '@pluralsight/react-ng';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectUserCertifications,
  SelectViewCertificate,
} from '../../state/selectors';
import {
  clearViewCertificateURL,
  fetchCertificationProviders,
  fetchUserCertifications,
} from '../../state/slice';
import AddCertificate from '../add-certificate/add-certificate';
import CertificationForm from '../certification-form/certification-form';
import CertificationList from '../certification-list/certification-list';
import ProfileCard from '../profile-card/profile-card';
import useParsedDate from '../../hooks/use-parsed-date';
import './certifications-tile.scss';

import type { Certification } from '../../state/interfaces';

const CertificatesContainer = () => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [psId, setPsId] = useState('');
  const [editCertificate, setEditCertificate] = useState<Certification | null>(
    null,
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const certifications = useSelector(selectUserCertifications);
  const viewCertificateUrl = useSelector(SelectViewCertificate);
  const { parseToCustomDate } = useParsedDate();

  const userCertifications = certifications?.map((certification) => {
    return {
      ...certification,
      achievedDate: parseToCustomDate(certification.achievedDate),
      expirationDate: parseToCustomDate(certification.expirationDate),
    };
  });

  const addCertificate = () => setIsEditMode(true);

  const cancelAddCertificate = () => {
    setIsEditMode(false);
    setEditCertificate(null);
  };

  const onCertificateEdit = (certificate: Certification) => {
    setEditCertificate(certificate);
    setIsEditMode(!isEditMode);
  };

  const redirectToCertificationHelp = () => {
    window.location.href =
      'https://help.pluralsight.com/hc/en-us/articles/24426491196564-Adding-industry-certifications-to-your-Skills-profile';
  };

  useEffect(() => {
    if (viewCertificateUrl) {
      window.open(viewCertificateUrl, '_blank');
      dispatch(clearViewCertificateURL());
    }
  }, [viewCertificateUrl]);

  const renderHelp = () => (
    <Button
      className="certifications-container__action"
      size="md"
      palette="action"
      usage="text"
      onClick={redirectToCertificationHelp}
      aria-label={t('certifications.certificationsContainer.help')}
    >
      {t('certifications.certificationsContainer.help')}
    </Button>
  );

  const renderHeaderAction = () =>
    userCertifications?.length ? (
      <FlexItem>
        {renderHelp()}
        <Button
          className="certifications-container__add"
          size="md"
          palette="action"
          usage="filled"
          startIcon={<PlusIcon aria-hidden="true" />}
          onClick={addCertificate}
          aria-label={t(
            'certifications.certificationsContainer.addCertificateBtn',
          )}
        >
          {t('certifications.certificationsContainer.addCertificateBtn')}
        </Button>
      </FlexItem>
    ) : (
      renderHelp()
    );

  const renderWidgetLayout = () => {
    if (isEditMode)
      return (
        <CertificationForm
          onCancel={cancelAddCertificate}
          initialFormValue={editCertificate}
        />
      );
    else if (userCertifications?.length) {
      return (
        <CertificationList
          certifications={userCertifications}
          onEdit={onCertificateEdit}
        />
      );
    } else {
      return <AddCertificate addCertificate={addCertificate} />;
    }
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem('userLoggedIn');

    if (storedUser) {
      const userLoggedIn = JSON.parse(storedUser) as {
        appMetadata: { psId: string };
      };

      setPsId(userLoggedIn.appMetadata.psId);
    }
  }, [psId]);

  useEffect(() => {
    if (psId) {
      dispatch(fetchUserCertifications(psId));
      dispatch(fetchCertificationProviders());
    }
  }, [dispatch, psId]);

  return (
    <div className="certifications-container">
      <ProfileCard>
        <FlexContainer
          className="certifications-container__header"
          justify="spaceBetween"
          wrap="wrap"
          gap={20}
        >
          <h2 className="certifications-container__title">
            {t('certifications.certificationsContainer.title')}
          </h2>
          {renderHeaderAction()}
        </FlexContainer>
        {renderWidgetLayout()}
      </ProfileCard>
    </div>
  );
};

export default CertificatesContainer;
