import { ChevronDownIcon, ChevronUpIcon } from '@pluralsight/icons';
import {
  FieldMessage,
  FlexContainer,
  Show,
  useFormControl,
} from '@pluralsight/react-ng';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './custom-select.scss';

type ErrorType = string | null;

export type OptionType = {
  label: string;
  value: string;
};

interface ICustomSelect {
  initialValue?: OptionType | null;
  onValueUpdate?: (value: { name: string; option: OptionType }) => void;
  name: string;
  options: OptionType[];
  errorMsg: string;
  placeholder?: string;
  label?: string;
  id?: string;
}

const CustomSelect = ({
  initialValue,
  onValueUpdate,
  options,
  name,
  placeholder,
  label,
  id,
  errorMsg,
}: ICustomSelect) => {
  const [dropdown, setDropdown] = useState<boolean | null>(null);
  const [selected, setSelected] = useState<OptionType | null | undefined>(
    initialValue || null,
  );
  const [error, setError] = useState<ErrorType>(null);
  const [touched, setTouched] = useState<boolean>(false);

  const { invalid, required, disabled } = useFormControl();
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const ulRef = useRef<HTMLUListElement>(null);

  const handleClick = () => {
    setTouched(true);
    setDropdown(!dropdown);
  };

  const handleItemClick = (option: OptionType) => {
    setSelected(option);
    setDropdown(!dropdown);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();

        if (dropdown && selected === null && ulRef.current) {
          const el = ulRef.current.childNodes[0] as HTMLLIElement;

          el.focus();

          break;
        }

        if (selected === null) {
          setSelected(options[0]);

          break;
        }

        if (selected && options.indexOf(selected) === options.length - 1) {
          setSelected(options[0]);

          break;
        }

        if (selected) {
          setSelected(options[options.indexOf(selected) + 1]);

          break;
        }

        break;

      case 'ArrowUp':
        e.preventDefault();

        if (dropdown && selected === null && ulRef.current) {
          const lastIndex = ulRef.current.childNodes.length - 1;
          const el = ulRef.current.childNodes[lastIndex] as HTMLLIElement;

          el.focus();

          break;
        }

        if (selected === null) {
          setSelected(options[options.length - 1]);

          break;
        }

        if (selected && options.indexOf(selected) === 0) {
          setSelected(options[options.length - 1]);

          break;
        }

        if (selected) {
          setSelected(options[options.indexOf(selected) - 1]);
        }

        break;

      default:
        break;
    }
  };

  const collapse = () => {
    setDropdown(false);

    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };

  const handleOptionKeyDown = (
    e: React.KeyboardEvent<HTMLLIElement>,
    option: OptionType,
  ) => {
    switch (e.key) {
      case 'ArrowDown': {
        e.preventDefault();

        const el = (e.currentTarget.nextSibling ||
          e.currentTarget.parentNode?.childNodes[0]) as HTMLLIElement;

        el.focus();

        break;
      }
      case 'ArrowUp':
        e.preventDefault();

        if (e.currentTarget.previousSibling) {
          const el = e.currentTarget.previousSibling as HTMLLIElement;

          el.focus();
        } else if (e.currentTarget.parentNode) {
          const indexOfLastElement =
            e.currentTarget?.parentNode?.childNodes?.length - 1;
          const el = e.currentTarget.parentNode?.childNodes[
            indexOfLastElement
          ] as HTMLLIElement;

          el.focus();
        }

        break;
      case 'Tab':
        e.preventDefault();

        break;
      case ' ':
        setSelected(option);
        collapse();

        break;
      case 'Escape':
        e.preventDefault();
        collapse();

        break;
      default:
        break;
    }
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (ref.current && !ref.current.contains(e.target as Node)) {
      setDropdown((prev) => (prev ? false : null));
    }
  };

  useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (
      (touched && required && !dropdown && !selected) ||
      (dropdown === false && selected === null)
    ) {
      setError(errorMsg);
    } else if (selected) {
      setError('');
    }
  }, [t, dropdown]);

  useEffect(() => {
    if (invalid) {
      setError(errorMsg);
    } else {
      setError('');
    }
  }, [invalid]);

  useEffect(() => {
    if (onValueUpdate !== undefined && selected) {
      onValueUpdate({
        name,
        option: selected,
      });
    }
  }, [selected]);

  useEffect(() => {
    if (dropdown && ulRef.current && selected) {
      const index = options.findIndex((opt) => opt.value === selected.value);
      const el = ulRef.current.childNodes[index] as HTMLLIElement;

      if (el) {
        el.focus();
      }
    }
  }, [dropdown]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <FlexContainer className="custom-select" direction="col">
      <Show when={!!label}>
        <label
          className={`custom-select__label ${invalid || error ? 'custom-select__label--error' : ''}`}
          htmlFor={id}
        >
          {label} {required ? t('customSelect.required') : ''}
        </label>
      </Show>
      <FlexContainer
        direction="col"
        className="custom-select__container"
        ref={ref}
      >
        <button
          className={`custom-select__container__input ${error || invalid ? 'custom-select__container__input--error' : ''}`}
          ref={buttonRef}
          id={id}
          type="button"
          disabled={disabled}
          onClick={() => handleClick()}
          onKeyDown={(e) => handleKeyDown(e)}
          role="combobox"
          ria-haspopup="listbox"
          aria-expanded={dropdown || false}
          aria-label={
            selected
              ? selected.label
              : placeholder || t('customSelect.pleaseSelect')
          }
          aria-controls={`${id}-listbox`}
          {...(invalid && { 'aria-invalid': true, invalid: 'true' })}
          aria-required={required}
        >
          <span
            className="custom-select__container__input__label"
            id={`${id}-option`}
          >
            {selected
              ? selected.label
              : placeholder || t('customSelect.pleaseSelect')}
          </span>
          {dropdown ? (
            <ChevronUpIcon className="custom-select__container__input__icon" />
          ) : (
            <ChevronDownIcon className="custom-select__container__input__icon" />
          )}
        </button>

        <Show when={!!dropdown}>
          <ul
            className="custom-select__container__overlay"
            ref={ulRef}
            role="listbox"
            id={`${id}-listbox`}
          >
            {options &&
              options.map((option: OptionType, i) => {
                return (
                  <li
                    className={`custom-select__container__overlay__item ${selected && selected.value === option.value ? 'custom-select__container__overlay__item--selected' : ''}`}
                    key={i}
                    onClick={() => handleItemClick(option)}
                    onKeyDown={(e) => handleOptionKeyDown(e, option)}
                    tabIndex={dropdown ? 0 : -1}
                    role="option"
                    aria-label={option.label}
                    aria-selected={selected?.value === option.value}
                  >
                    {option.label}
                  </li>
                );
              })}
          </ul>
        </Show>
        <Show when={!!invalid || !!error}>
          <FieldMessage
            className="custom-select__container--error"
            aria-live="assertive"
            id={`${id}:invalid`}
          >
            {error}
          </FieldMessage>
        </Show>
      </FlexContainer>
    </FlexContainer>
  );
};

export default CustomSelect;
