import React from 'react';
import { Button, FlexContainer } from '@pluralsight/react-ng';
import { useTranslation } from 'react-i18next';

import CertificationHeadingIcon from '../../../../assets/certification-heading-icon.svg';
import './add-certificate.scss';

const AddCertificate = ({ addCertificate }: { addCertificate: () => void }) => {
  const { t } = useTranslation();

  const redirectToCertificationCenter = () => {
    window.location.href = 'https://app.pluralsight.com/explore/certifications';
  };

  return (
    <FlexContainer className="add-certificate">
      <FlexContainer
        className="add-certificate-content"
        direction="col"
        align="center"
      >
        <img src={CertificationHeadingIcon} alt="" role="presentation" />
        <h3 className="add-certificate-content__title">
          {t('certifications.addCertificate.title')}
        </h3>
        <p className="add-certificate-content__description">
          "{t('certifications.addCertificate.uploadCertificate')}
          <br />
          {t('certifications.addCertificate.noCertificateYet')}"
        </p>

        <FlexContainer gap={10} justify="center" wrap="wrap">
          <Button
            className="add-certificate-content__btn"
            size="md"
            palette="action"
            usage="outline"
            onClick={redirectToCertificationCenter}
          >
            {t('certifications.addCertificate.browsePathBtn')}
          </Button>
          <Button
            className="add-certificate-content__btn"
            size="md"
            palette="action"
            usage="filled"
            onClick={addCertificate}
          >
            {t('certifications.addCertificate.addCertificateBtn')}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </FlexContainer>
  );
};

export default AddCertificate;
