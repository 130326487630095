import { EllipsisVerticalIcon } from '@pluralsight/icons';
import { Avatar, FlexContainer, FlexItem } from '@pluralsight/react-ng';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import OptionsDropdown from '../../../../components/options-dropdown/options-dropdown';
import { getRelativeTime } from '../../../../shared/utils';

import type { TranslationFlags } from '../../../../shared/utils';
import type { Notification } from '../../state/interfaces';
import './notification.scss';

interface NotificationProps {
  notification: Notification;
  readNotification(notification: Notification): void;
  deleteNotification(notificationId: string): void;
}

const Notifications: React.FC<NotificationProps> = ({
  notification,
  readNotification,
  deleteNotification,
}) => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language as TranslationFlags;
  const [modalStatus, setModalStatus] = useState(false);

  const updateModalStatus = () => {
    setModalStatus(!modalStatus);
  };

  return (
    <>
      <FlexContainer className="notification-item" key={notification.id}>
        <FlexItem>
          <Avatar
            className="notification-item__avatar"
            label={'Avatar'}
            img={
              <img
                className="notification-item__avatar__img"
                src={notification.iconUrl}
                alt="Avatar"
              />
            }
          />
        </FlexItem>
        <FlexContainer className="notification-item__body">
          <FlexItem
            className="notification-item__body__title"
            dangerouslySetInnerHTML={{ __html: notification.body }}
          />
          <FlexItem className="notification-item__body__time-interval">
            {getRelativeTime(
              notification.expiresDate.toString(),
              currentLanguage,
            )}
          </FlexItem>
        </FlexContainer>
        <OptionsDropdown
          button={<EllipsisVerticalIcon />}
          isOpen={modalStatus}
          options={
            <>
              <div
                className="common-dropdown-option"
                onClick={() => {
                  readNotification(notification);
                  updateModalStatus();
                }}
                role="button"
              >
                <span className="dropdown-option-span">
                  {t('notifications.optionsDropdown.markAsRead')}
                </span>
                <span className="dropdown-option-description">
                  {t('notifications.optionsDropdown.markAsReadDescription')}
                </span>
              </div>
              <div
                className="common-dropdown-option"
                onClick={() => {
                  deleteNotification(notification.id);
                  updateModalStatus();
                }}
                role="button"
              >
                <span className="dropdown-option-span">
                  {t('notifications.optionsDropdown.delete')}
                </span>
                <span className="dropdown-option-description">
                  {t('notifications.optionsDropdown.deleteDescription')}
                </span>
              </div>
            </>
          }
          setIsOpen={() => {
            updateModalStatus();
          }}
        />
      </FlexContainer>
      <FlexItem className="separator" />
    </>
  );
};

export default Notifications;
