import { isValid, parse } from 'date-fns';

import type { Certification } from '../../state/interfaces';

const MIN_YEAR = 1970;
const DATE_FORMAT = 'MM/dd/yyyy';

const parseDate = (dateString?: string): Date | undefined => {
  if (!dateString) {
    return undefined;
  }

  const parsedDate = parse(dateString, DATE_FORMAT, new Date());

  return isValid(parsedDate) && parsedDate.getFullYear() >= MIN_YEAR
    ? parsedDate
    : undefined;
};

export const isCertificationDateValid = (
  fieldName: string,
  value: string,
  formFields: Certification,
): boolean => {
  const currentDate = new Date();
  const inputDate = parseDate(value);

  if (!inputDate || inputDate.getFullYear() < MIN_YEAR) {
    return false;
  }

  if (fieldName === 'achievedDate' && inputDate > currentDate) {
    return false;
  }

  const achievedDate = parseDate(formFields.achievedDate);
  const expirationDate = parseDate(formFields.expirationDate);

  switch (fieldName) {
    case 'achievedDate':
      return !expirationDate || inputDate <= expirationDate;
    case 'expirationDate':
      return !achievedDate || inputDate >= achievedDate;
    default:
      return false;
  }
};
