// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-info {
  display: grid;
  grid-column-gap: var(--pando-spacing-6);
  grid-template-columns: 80px 1fr 80px;
}
.profile-info__nickname {
  color: var(--pando-colors-neutral-text-300);
  font-size: var(--font-size-3xl, 32px);
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  margin: var(--pando-spacing-1) 0;
}
.profile-info__position {
  color: var(--pando-colors-neutral-text-200);
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.profile-info__input {
  margin-bottom: var(--pando-spacing-6);
}
.profile-info__pencil-icon {
  cursor: pointer;
}
.profile-info__edit-text {
  color: var(--pando-colors-action-text-100);
  cursor: pointer;
  font-size: var(--font-size-sm, 14px);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/profile-info/profile-info.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,uCAAA;EACA,oCAAA;AAAF;AAEE;EACE,2CAAA;EACA,qCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gCAAA;AAAJ;AAGE;EACE,2CAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AADJ;AAIE;EACE,qCAAA;AAFJ;AAKE;EACE,eAAA;AAHJ;AAME;EACE,0CAAA;EACA,eAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAJJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.profile-info {\n  display: grid;\n  grid-column-gap: var(--pando-spacing-6);\n  grid-template-columns: 80px 1fr 80px;\n\n  &__nickname {\n    color: var(--pando-colors-neutral-text-300);\n    font-size: var(--font-size-3xl, 32px);\n    font-style: normal;\n    font-weight: 600;\n    line-height: 125%;\n    margin: var(--pando-spacing-1) 0;\n  }\n\n  &__position {\n    color: var(--pando-colors-neutral-text-200);\n    font-size: var(--font-size-sm, 14px);\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%;\n  }\n\n  &__input {\n    margin-bottom: var(--pando-spacing-6);\n  }\n\n  &__pencil-icon {\n    cursor: pointer;\n  }\n\n  &__edit-text {\n    color: var(--pando-colors-action-text-100);\n    cursor: pointer;\n    font-size: var(--font-size-sm, 14px);\n    font-style: normal;\n    font-weight: 500;\n    line-height: 150%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
