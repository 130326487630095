import { FlexContainer } from '@pluralsight/react-ng';
import React from 'react';
import './settings-card.scss';

interface SettingsCardProps {
  children: React.ReactNode;
  header: string;
  subheader: string;
}

const SettingsCard: React.FC<SettingsCardProps> = ({
  children,
  header,
  subheader,
}) => {
  return (
    <FlexContainer className="settings-card" direction="col">
      <div className="settings-card__header">
        <h2 className="settings-card__header-title">{header}</h2>
      </div>
      <div className="settings-card__subheader">
        <span className="settings-card__subheader-span">{subheader}</span>
      </div>
      <div>{children}</div>
    </FlexContainer>
  );
};

export default SettingsCard;
