import React, { useEffect } from 'react';
import {
  ConfirmProvider,
  PromptProvider,
  ToastProvider,
} from '@pluralsight/react-ng';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { TranslationsProvider } from '@ps-fe-common-pages/contexts/TranslationsContext';
import { resources } from '@ps-fe-common-pages/translations';
import { UserProvider } from '@ps-fe-common-pages/contexts/UserContext';
import store, { persistor, sagaMiddleware } from '@ps-fe-common-pages/store';
import httpClient from '@ps-fe-common-pages/api/http-client';
import { AppConfigProvider } from '@ps-fe-common-pages/contexts/AppConfigContext';

import type { AppConfig } from '@ps-fe-common-pages/contexts/AppConfigContext';
import type { UserAppData } from '@ps-fe-common-pages/types';
import type { PropsWithChildren, FC } from 'react';

interface Props extends PropsWithChildren {
  initialUser: UserAppData | null;
  initialLanguage: string;
  appConfig: AppConfig | null;
}

const queryClient = new QueryClient();

const ProvidersLayer: FC<Props> = ({
  children,
  initialUser,
  initialLanguage,
  appConfig,
}) => {
  useEffect(() => {
    sagaMiddleware.setContext({ httpClient });
  }, [httpClient]);

  return (
    <AppConfigProvider appConfig={appConfig}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <UserProvider initialUser={initialUser}>
              <TranslationsProvider
                resources={resources}
                initialLanguage={initialLanguage}
              >
                <PromptProvider>
                  <ToastProvider>
                    <ConfirmProvider>{children}</ConfirmProvider>
                  </ToastProvider>
                </PromptProvider>
              </TranslationsProvider>
            </UserProvider>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </AppConfigProvider>
  );
};

export default ProvidersLayer;
