import React from 'react';

import ProvidersLayer from '@ps-fe-common-pages/components/ProvidersLayer';

import App from './components/app/app';

import './root.component.scss';
import type { AxiosInstance } from 'axios';
import type { AppConfig } from '@ps-fe-common-pages/contexts/AppConfigContext';

export interface INotificationsRootProps {
  httpClient: AxiosInstance;
}

const NotificationsRootComponent: React.FC<INotificationsRootProps> = () => {
  const config = (window as unknown as { NEXTGEN_CONFIG: AppConfig })
    .NEXTGEN_CONFIG;

  return (
    <ProvidersLayer
      initialLanguage={'en'}
      initialUser={null}
      appConfig={config}
    >
      <App />
    </ProvidersLayer>
  );
};

export default NotificationsRootComponent;
