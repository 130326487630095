import type { AxiosInstance } from 'axios';
import type {
  Certification,
  CertificationUploadUrlResponse,
  Skill,
  UserProfileInfo,
  UserProfileInfoResponseItem,
} from './interfaces';

const API_VERSION = '/v1';
const USERS_PATH = '/users';
const PROFILE_PATH = '/profile';

export class UserProfileInfoService {
  private httpClient: AxiosInstance;

  constructor(httpClient: AxiosInstance) {
    this.httpClient = httpClient;
  }

  fetchUserProfileInfo = async (
    psId: string,
  ): Promise<UserProfileInfoResponseItem> => {
    try {
      const response = await this.httpClient.get<UserProfileInfoResponseItem>(
        `${API_VERSION}${USERS_PATH}/${psId}/profile-info`,
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching user profile info:', error);

      throw error;
    }
  };

  patchUserProfileInfo = async (
    psId: string,
    patch: Partial<UserProfileInfo>,
  ): Promise<UserProfileInfoResponseItem> => {
    try {
      const response = await this.httpClient.patch(
        `${API_VERSION}${USERS_PATH}/${psId}/profile-info`,
        patch,
      );

      return response.data;
    } catch (error) {
      console.error('Error patching user profile info:', error);

      throw error;
    }
  };

  fetchUserSkills = async (psId: string): Promise<Skill[]> => {
    try {
      const response = await this.httpClient.get(
        `${API_VERSION}${USERS_PATH}/${psId}/skills`,
      );

      return response.data;
    } catch (error) {
      console.error('Error getting user skills info:', error);

      throw error;
    }
  };

  fetchUserCertifications = async (
    userId: string,
  ): Promise<Certification[]> => {
    try {
      const response = await this.httpClient.get(
        `${API_VERSION}${USERS_PATH}/${userId}/profile-certifications`,
      );

      return response.data;
    } catch (error) {
      console.error('Error getting user certifications', error);

      throw error;
    }
  };

  postUserCertification = async (
    certification: Certification,
    userId: string,
  ): Promise<{ id: string }> => {
    try {
      const data = {
        certificationId: certification.certificationId,
        validationCode: certification.validationCode,
        achievedDate: certification.achievedDate,
        expirationDate: certification.expirationDate || null,
      };

      const response = await this.httpClient.post(
        `${API_VERSION}${USERS_PATH}/${userId}/profile-certifications`,
        data,
      );

      return response.data;
    } catch (error) {
      console.error('Error getting user certifications', error);

      throw error;
    }
  };

  deleteUserCertification = async (certificationId: string, userId: string) => {
    try {
      await this.httpClient.delete(
        `${API_VERSION}${USERS_PATH}/${userId}/profile-certifications/${certificationId}`,
      );
    } catch (error) {
      console.error('Error deleting user certification', error);

      throw error;
    }
  };

  patchUserCertification = async (
    certification: Certification,
    userId: string,
  ): Promise<Certification> => {
    try {
      const data = {
        certificationId: certification.certificationId,
        validationCode: certification.validationCode,
        achievedDate: certification.achievedDate,
        expirationDate: certification.expirationDate || null,
      };

      const response = await this.httpClient.patch(
        `${API_VERSION}${USERS_PATH}/${userId}/profile-certifications/${certification.id}`,
        data,
      );

      return response.data;
    } catch (error) {
      console.error('Error getting user certifications', error);

      throw error;
    }
  };

  fetchUploadCertificationUrl = async (
    userId: string,
    certificationId: string,
  ): Promise<CertificationUploadUrlResponse> => {
    try {
      const response = await this.httpClient.get(
        `${API_VERSION}${USERS_PATH}/${userId}/profile-certifications/${certificationId}/upload/url`,
      );

      return response.data;
    } catch (error) {
      console.error('Error getting upload URL', error);

      throw error;
    }
  };

  postCertificationFile = async (
    url: string,
    file: File,
  ): Promise<{ id: string }> => {
    try {
      const response = await this.httpClient.put(url, file, {
        headers: {
          'Content-Type': file.type,
          'x-amz-server-side-encryption': 'AES256',
        },
        transformRequest: [
          (data, headers) => {
            delete headers.Authorization;

            return data;
          },
        ],
      });

      return response.data;
    } catch (error) {
      console.error('Error getting upload URL', error);

      throw error;
    }
  };

  fetchCertificationProviders = async (): Promise<
    { value: string; label: string }[]
  > => {
    try {
      const response = await this.httpClient.get(
        `${API_VERSION}${PROFILE_PATH}/certifications`,
      );

      return response.data;
    } catch (error) {
      console.error('Error getting fetching certification providers', error);

      throw error;
    }
  };

  fetchViewCertificateUrl = async (
    userId: string,
    certificationId: string,
  ): Promise<string> => {
    try {
      const response = await this.httpClient.get(
        `${API_VERSION}${USERS_PATH}/${userId}/profile-certifications/${certificationId}/view/url`,
      );

      return response.data;
    } catch (error) {
      console.error('Error getting certificate URL', error);

      throw error;
    }
  };
}
