import { PencilIcon } from '@pluralsight/icons';
import {
  Button,
  FieldMessage,
  FlexContainer,
  FlexItem,
  FormControlProvider,
  IconButton,
  Input,
  Show,
} from '@pluralsight/react-ng';
import React, { useEffect, useState } from 'react';
import './personal-details.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useUser } from '@ps-fe-common-pages/contexts/UserContext';

import { patchUser } from '../../../user/state/slice';
import { selectUser } from '../../../user/state/selectors';

const ILLEGAL_CHARACTERS = '/^[§!@#$%^&*()_+=[]{};:"\\|,.<>/?]*$/';
const MAX_LENGTH = 50;

const PersonalDetails = () => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const { refetch: refetchUser } = useUser();

  const dispatch = useDispatch();
  const {
    firstName = '',
    lastName = '',
    email = '',
    psUserId = '',
    isTermsAccepted = false,
    country = '',
  } = user || {};
  const [error, setError] = useState({ firstName: null, lastName: null });
  const [nameUpdate, setNameUpdate] = useState({ firstName, lastName });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setNameUpdate({ firstName, lastName });
  }, [firstName, lastName]);

  const checkErrors = (name: string, value: string) => {
    if (!value || !value.trim().length) {
      setError({
        ...error,
        [name]: t('settings.settingsContainer.nameEmptyError'),
      });
    } else if (value.length > MAX_LENGTH) {
      setError({
        ...error,
        [name]: t('settings.settingsContainer.nameMaxLengthError'),
      });
    } else if (ILLEGAL_CHARACTERS.split('').some((ch) => value.includes(ch))) {
      setError({
        ...error,
        [name]: t('settings.settingsContainer.nameSpecialCharsError'),
      });
    } else {
      setError({ ...error, [name]: null });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    checkErrors(e.target.name, e.target.value);
    setNameUpdate({ ...nameUpdate, [e.target.name]: e.target.value });
  };

  const handleCancel = () => {
    setIsEditing(false);
    setNameUpdate({ firstName, lastName });
    setError({ firstName: null, lastName: null });
  };

  const handleSubmit = () => {
    if (!!error.firstName || !!error.lastName) return;

    dispatch(
      patchUser({ psUserId, email, isTermsAccepted, country, ...nameUpdate }),
    );
    setIsEditing(false);
  };

  useEffect(() => {
    const storedUser = sessionStorage.getItem('userLoggedIn');

    if (storedUser) {
      const userStoredInfo = JSON.parse(storedUser);
      const userUpdatedInfo = {
        ...userStoredInfo,
        username: `${user?.firstName} ${user?.lastName}`,
      };

      sessionStorage.setItem('userLoggedIn', JSON.stringify(userUpdatedInfo));
      void refetchUser();
    }
  }, [user]);

  return (
    <>
      <FlexContainer className="personal-details">
        <FlexContainer direction="col" justify="spaceBetween">
          <FlexItem>
            <h3>{t('settings.settingsContainer.fullName')}</h3>
          </FlexItem>
        </FlexContainer>
        <FlexContainer align="center" justify="flexEnd">
          <FlexItem className="personal-details__username">
            {firstName || ''} {lastName || ''}
          </FlexItem>
          <Show when={!isEditing}>
            <IconButton
              className="personal-details__pencil-icon"
              palette="action"
              usage="text"
              size="sm"
              ariaLabel={`Edit your fullname ${firstName} ${lastName}`}
              onClick={() => setIsEditing(true)}
            >
              <PencilIcon />
            </IconButton>
          </Show>
        </FlexContainer>
      </FlexContainer>
      <Show when={isEditing}>
        <FlexContainer className="personal-details__form" direction="col">
          <FormControlProvider invalid={!!error.firstName}>
            <FlexItem className="personal-details__input">
              <span className="personal-details__label">
                {t('settings.settingsContainer.firstNameRequired')}
              </span>
              <Input
                id="firstName"
                name="firstName"
                placeholder=""
                size="md"
                value={nameUpdate.firstName || ''}
                onChange={handleChange}
                aria-label={t('settings.settingsContainer.firstNameAriaLabel')}
              />
              <Show when={!!error.firstName}>
                <FieldMessage id="firstName">{error.firstName}</FieldMessage>
              </Show>
            </FlexItem>
          </FormControlProvider>
          <FormControlProvider invalid={!!error.lastName}>
            <FlexItem className="personal-details__input">
              <span className="personal-details__label">
                {t('settings.settingsContainer.lastNameRequired')}
              </span>
              <Input
                id="lastName"
                name="lastName"
                placeholder=""
                size="md"
                value={nameUpdate.lastName || ''}
                onChange={handleChange}
                aria-label={t('settings.settingsContainer.lastNameAriaLabel')}
              />
              <Show when={!!error.lastName}>
                <FieldMessage id="lastName">{error.lastName}</FieldMessage>
              </Show>
            </FlexItem>
          </FormControlProvider>
        </FlexContainer>
        <FlexContainer>
          <Button
            className="personal-details__button"
            size="lg"
            palette="action"
            usage="outline"
            onClick={handleCancel}
          >
            {t('settings.settingsContainer.cancel')}
          </Button>
          <div className="personal-details__spacing" />
          <Button
            className="personal-details__button"
            size="lg"
            palette="action"
            usage="filled"
            onClick={handleSubmit}
            disabled={!!error.firstName || !!error.lastName}
          >
            {t('settings.settingsContainer.save')}
          </Button>
        </FlexContainer>
      </Show>
    </>
  );
};

export default PersonalDetails;
