import { createSelector } from 'reselect';

import type { AppState } from '../../../store';
import type {
  Skill,
  Certification,
  UserProfileInfo,
  UserProfileInfoState,
  Providers,
} from './interfaces';

const selectUserProfileInfoState = (state: AppState) => state.userProfileInfo;

export const selectUserProfileInfo = createSelector(
  [selectUserProfileInfoState],
  (userProfileInfoState: UserProfileInfoState): UserProfileInfo | null =>
    userProfileInfoState.item,
);

export const selectUserSkills = createSelector(
  [selectUserProfileInfoState],
  (userProfileInfoState: UserProfileInfoState): Skill[] | null =>
    userProfileInfoState.skills,
);

export const selectUserCertifications = createSelector(
  [selectUserProfileInfoState],
  (selectUserProfileInfoState: UserProfileInfoState): Certification[] | null =>
    selectUserProfileInfoState.certifications,
);

export const SelectCertificationProviders = createSelector(
  [selectUserProfileInfoState],
  (userProfileState: UserProfileInfoState): Providers[] =>
    userProfileState.providers,
);

export const SelectViewCertificate = createSelector(
  [selectUserProfileInfoState],
  (userProfileState: UserProfileInfoState): string | null =>
    userProfileState.viewCertificate,
);
