// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-card-option__header {
  font-size: 14px;
}
.settings-card-option__subheader {
  color: var(--pando-colors-neutral-text-200);
  font-size: var(--pando-font-sizes-sm);
}
.settings-card-option__value {
  margin: 0 var(--pando-spacing-4);
}
.settings-card-option__icon {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/features/settings/components/settings-card-option/settings-card-option.scss"],"names":[],"mappings":"AAEE;EACE,eAAA;AADJ;AAIE;EACE,2CAAA;EACA,qCAAA;AAFJ;AAKE;EACE,gCAAA;AAHJ;AAME;EACE,eAAA;AAJJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.settings-card-option {\n  &__header {\n    font-size: 14px;\n  }\n\n  &__subheader {\n    color: var(--pando-colors-neutral-text-200);\n    font-size: var(--pando-font-sizes-sm);\n  }\n\n  &__value {\n    margin: 0 var(--pando-spacing-4);\n  }\n\n  &__icon {\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
