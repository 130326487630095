export interface NotificationsState {
  error: NotificationsMessage | string | null;
  list: Notification[];
  loading: boolean;
  readMessage: NotificationsMessage | null;
}

export enum NotificationStatus {
  read = 'Read',
  sentToUser = 'SentToUser',
}

export interface Notification {
  id: string;
  psId: string;
  body: string;
  category: string;
  link: string;
  status: NotificationStatus;
  expiresDate: Date;
  imageUrl: string;
  iconUrl: string;
  readDate: Date;
  createdDate: Date;
  archivedDate: Date | null;
  userFirstClickedOn: Date;
  userFirstCompletedOn: Date;
}

export interface NotificationsMessage {
  status?: number;
  success?: boolean;
  message: string;
  error?: string;
}
