import { createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  Skill,
  Certification,
  UserProfileInfo,
  UserProfileInfoState,
  Providers,
} from './interfaces';

const INITIAL_STATE: UserProfileInfoState = {
  error: null,
  item: null,
  loading: false,
  skills: null,
  certifications: [],
  providers: [],
  viewCertificate: null,
};

const userProfileInfoSlice = createSlice({
  name: 'userProfileInfo',
  initialState: INITIAL_STATE,
  reducers: {
    fetchUserProfileInfo: (state) => {
      state.error = null;
      state.loading = true;
    },
    fetchUserProfileInfoSuccess: (
      state,
      action: PayloadAction<UserProfileInfo>,
    ) => {
      state.item = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchUserProfileInfoFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchUserSkills: (state) => {
      state.error = null;
      state.loading = true;
    },
    fetchUserSkillsSuccess: (state, action: PayloadAction<Skill[]>) => {
      state.skills = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchUserSkillsFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    patchUserProfileInfo: (state) => {
      state.error = null;
      state.loading = true;
    },
    patchUserProfileInfoSuccess: (
      state,
      action: PayloadAction<UserProfileInfo>,
    ) => {
      state.item = action.payload;
      state.loading = false;
      state.error = null;
    },
    patchUserProfileInfoFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchUserCertifications: (state, _action: PayloadAction<string>) => {
      state.error = null;
      state.loading = true;
    },
    fetchUserCertificationsSuccess: (
      state,
      action: PayloadAction<Certification[]>,
    ) => {
      state.loading = false;
      state.error = null;
      state.certifications = action.payload;
    },
    fetchUserCertificationsFailure: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    },
    postUserCertification: (
      state,
      _action: PayloadAction<{ value: Certification }>,
    ) => {
      state.error = null;
      state.loading = true;
    },
    postUserCertificationSuccess: (
      state: UserProfileInfoState,
      action: PayloadAction<Certification>,
    ) => {
      state.error = null;
      state.loading = true;
      state.certifications = [action.payload, ...state.certifications];
    },
    postUserCertificationFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = true;
    },
    deleteCertification: (state, _action: PayloadAction<{ id: string }>) => {
      state.error = null;
      state.loading = true;
    },
    deleteCertificationSuccess: (
      state,
      action: PayloadAction<{ id: string }>,
    ) => {
      state.error = null;
      state.loading = false;
      state.certifications = state.certifications.filter(
        (cert) => cert.id !== action.payload.id,
      );
    },
    deleteCertificationFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    patchUserCertification: (
      state,
      _action: PayloadAction<{ value: Certification }>,
    ) => {
      state.error = null;
      state.loading = true;
    },
    patchUserCertificationSuccess: (
      state,
      action: PayloadAction<Certification>,
    ) => {
      state.error = null;
      state.loading = true;
      state.certifications = state.certifications.map((cert: Certification) =>
        cert.id === action.payload.id ? action.payload : cert,
      );
    },
    patchUserCertificationFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    fetchUploadCertificationUrl: (
      state,
      _action: PayloadAction<{ id: string }>,
    ) => {
      state.error = null;
      state.loading = true;
    },
    fetchUploadCertificationUrlSuccess: (
      state,
      _action: PayloadAction<{ url: string }>,
    ) => {
      state.error = null;
      state.loading = false;
    },
    fetchUploadCertificationUrlFailure: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.error = action.payload;
      state.loading = false;
    },
    postCertificationFile: (
      state,
      _action: PayloadAction<{ id: string; file: File[] }>,
    ) => {
      state.error = null;
      state.loading = false;
    },
    postCertificationFileSuccess: (
      state,
      action: PayloadAction<{ id: string; fileId: string }>,
    ) => {
      state.error = null;
      state.loading = true;
      state.certifications = state.certifications.map((cert: Certification) =>
        cert.id === action.payload.id
          ? { ...cert, fileId: action.payload.fileId }
          : cert,
      );
    },
    postCertificationFileFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = false;
    },
    patchCertificationFile: (
      state,
      _action: PayloadAction<{ id: string; file: File[] }>,
    ) => {
      state.error = null;
      state.loading = false;
    },
    fetchCertificationProviders: (state) => {
      state.error = null;
      state.loading = true;
    },
    fetchCertificationProvidersSuccess: (
      state,
      action: PayloadAction<Providers[]>,
    ) => {
      state.error = null;
      state.loading = true;
      state.providers = action.payload;
    },
    fetchCertificationProvidersFailure: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.error = action.payload;
      state.loading = true;
    },
    fetchCertificationUrl: (state, _action: PayloadAction<{ id: string }>) => {
      state.error = null;
      state.loading = true;
    },
    fetchCertificationUrlSuccess: (state, action: PayloadAction<string>) => {
      state.error = null;
      state.loading = true;
      state.viewCertificate = action.payload;
    },
    fetchCertificationUrlFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.loading = true;
    },
    clearViewCertificateURL: (state) => {
      state.viewCertificate = null;
    },
  },
});

export const {
  fetchUserProfileInfo,
  fetchUserProfileInfoSuccess,
  fetchUserProfileInfoFailure,
  fetchUserSkills,
  fetchUserSkillsSuccess,
  fetchUserSkillsFailure,
  patchUserProfileInfo,
  patchUserProfileInfoSuccess,
  patchUserProfileInfoFailure,
  fetchUserCertifications,
  fetchUserCertificationsSuccess,
  fetchUserCertificationsFailure,
  postUserCertification,
  postUserCertificationSuccess,
  postUserCertificationFailure,
  deleteCertification,
  deleteCertificationSuccess,
  deleteCertificationFailure,
  patchUserCertification,
  patchUserCertificationSuccess,
  patchUserCertificationFailure,
  fetchUploadCertificationUrl,
  fetchUploadCertificationUrlSuccess,
  fetchUploadCertificationUrlFailure,
  postCertificationFile,
  patchCertificationFile,
  postCertificationFileSuccess,
  postCertificationFileFailure,
  fetchCertificationProviders,
  fetchCertificationProvidersSuccess,
  fetchCertificationProvidersFailure,
  fetchCertificationUrl,
  fetchCertificationUrlSuccess,
  fetchCertificationUrlFailure,
  clearViewCertificateURL,
} = userProfileInfoSlice.actions;

export default userProfileInfoSlice.reducer;
