export interface UserProfileInfo {
  psId: string;
  companyName: string | null;
  jobRole: string | null;
  nickname: string;
}

export interface UserProfileInfoResponseItem {
  companyName: string | null;
  jobRole: string | null;
  nickname: string;
}

export interface Certification {
  id: string | null;
  providerName: string;
  certificationName: string;
  certificationId: string;
  achievedDate: string;
  expirationDate?: string;
  validationCode?: string;
  certificationFile: File[] | null;
  fileId: string | null;
  objectKey: string | null;
}

export interface Providers {
  providerId: string;
  providerName: string;
  certifications: ProviderCertifications[];
}

export interface ProviderCertifications {
  id: string;
  name: string;
}
export interface UserProfileInfoState {
  error: string | null;
  item: UserProfileInfo | null;
  loading: boolean;
  skills: Skill[] | null;
  certifications: Certification[];
  providers: Providers[];
  viewCertificate: string | null;
}

export enum SkillStatus {
  COMPLETED = 'completed',
  IN_PROGRESS = 'in_progress',
  BETA = 'beta',
  LIVE = 'live',
}

export enum SkillCategory {
  ABOVE_AVG = 'above_avg',
  AVERAGE = 'average',
  EMERGING = 'emerging',
  EXPERT = 'expert',
  NOVICE = 'novice',
}

export interface TestResults {
  category: SkillCategory;
  date: Date;
  id: string;
  percentile: string;
  result: string;
}

export interface Skill {
  id: string;
  iconUrl: string;
  lastUpdated?: Date;
  name: string;
  status: SkillStatus;
  testResults?: TestResults;
  url: string;
  verifiedDate?: Date;
}

export interface CertificationUploadUrlResponse {
  url: string;
}
