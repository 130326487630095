import React, { createContext, useContext, useEffect, useState } from 'react';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  useCustomEvent,
  CUSTOM_EVENTS,
} from '@ps-fe-common-pages/hooks/use-custom-event';

import type { TFunction, Resource } from 'i18next';
import type { FC, PropsWithChildren } from 'react';

interface ITranslationsContext {
  t: TFunction;
  language: string;
  setLanguage: (lang: string) => void;
}

interface IProviderProps {
  resources: Resource;
  initialLanguage: string;
}

const TranslationsContext = createContext<ITranslationsContext | null>(null);

export const TranslationsProvider: FC<PropsWithChildren<IProviderProps>> = (
  props,
) => {
  const [init, setInit] = useState(false);
  const [language, setLanguageState] = useState(i18n.language);

  const { sendCustomEvent } = useCustomEvent<string>({
    event: CUSTOM_EVENTS.LANGUAGE_UPDATED,
    callback: (data) => {
      void i18n.changeLanguage(data);
    },
  });

  const initTranslations = async () => {
    await i18n.use(initReactI18next).init({
      lng: props.initialLanguage,
      resources: props.resources,
      interpolation: {
        escapeValue: false,
      },
    });
    setInit(true);
    setLanguageState(i18n.language);
  };

  useEffect(() => {
    void initTranslations();

    const handleLanguageChanged = (lng: string) => {
      setLanguageState(lng);
    };

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [props.resources]);

  const setLanguage = (value: string) => {
    void i18n.changeLanguage(value);
    sendCustomEvent(CUSTOM_EVENTS.LANGUAGE_UPDATED, value);
  };

  return (
    <TranslationsContext.Provider value={{ t: i18n.t, setLanguage, language }}>
      {init && props.children}
    </TranslationsContext.Provider>
  );
};

export const useTranslations = () => {
  const context = useContext(TranslationsContext);

  if (!context) {
    throw new Error(
      'useTranslations must be used within a TranslationsProvider',
    );
  }

  return context;
};
