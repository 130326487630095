import { FlexContainer } from '@pluralsight/react-ng';
import React from 'react';
import './profile-card.scss';

interface ProfileCardProps {
  children: React.ReactNode;
}

// TODO (przem-wierzbicki): Can this be merged with SettingsCard?
const ProfileCard: React.FC<ProfileCardProps> = ({ children }) => {
  return (
    <FlexContainer className="profile-card" direction="col">
      {children}
    </FlexContainer>
  );
};

export default ProfileCard;
