// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/dotted-background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-certificate {
  position: relative;
  background-color: var(--pando-colors-neutral-surface-200);
  margin: var(--pando-spacing-4) 0 var(--pando-spacing-4);
  padding: var(--pando-spacing-8);
}
.add-certificate::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 20px;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center -45px;
  filter: hue-rotate(25deg);
}
.add-certificate-content {
  position: relative;
  text-align: center;
}
.add-certificate-content__title {
  margin-top: 0.5rem;
  font-size: var(--pando-font-sizes-lg);
  font-weight: var(--pando-font-weights-semibold);
}
.add-certificate-content__description {
  margin: 0.5rem 0 1rem 0;
  font-size: var(--pando-font-sizes-sm);
  font-family: var(--font-family-body, "PS TT Commons");
  color: var(--pando-colors-neutral-text-200);
}
.add-certificate-content__btn {
  width: 199px;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/add-certificate/add-certificate.scss"],"names":[],"mappings":"AACA;EACE,kBAAA;EACA,yDAAA;EACA,uDAAA;EACA,+BAAA;AAAF;AAEE;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,MAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,yDAAA;EACA,wBAAA;EACA,4BAAA;EACA,iCAAA;EACA,yBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,kBAAA;AADJ;AAGI;EACE,kBAAA;EACA,qCAAA;EACA,+CAAA;AADN;AAII;EACE,uBAAA;EACA,qCAAA;EACA,qDAAA;EACA,2CAAA;AAFN;AAKI;EACE,YAAA;AAHN","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.add-certificate {\n  position: relative;\n  background-color: var(--pando-colors-neutral-surface-200);\n  margin: var(--pando-spacing-4) 0 var(--pando-spacing-4);\n  padding: var(--pando-spacing-8);\n\n  &::before {\n    content: '';\n    display: block;\n    position: absolute;\n    top: 0;\n    right: 20px;\n    width: 100%;\n    height: 100%;\n    opacity: 0.3;\n    background-image: url('../../../../assets/dotted-background.png');\n    background-size: contain;\n    background-repeat: no-repeat;\n    background-position: center -45px;\n    filter: hue-rotate(25deg);\n  }\n\n  &-content {\n    position: relative;\n    text-align: center;\n\n    &__title {\n      margin-top: 0.5rem;\n      font-size: var(--pando-font-sizes-lg);\n      font-weight: var(--pando-font-weights-semibold);\n    }\n\n    &__description {\n      margin: 0.5rem 0 1rem 0;\n      font-size: var(--pando-font-sizes-sm);\n      font-family: var(--font-family-body, 'PS TT Commons');\n      color: var(--pando-colors-neutral-text-200);\n    }\n\n    &__btn {\n      width: 199px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
