import { SkillCategory, SkillStatus } from './interfaces';

import type { Skill } from './interfaces';

export const SKILLS_MOCK: Skill[] = [
  {
    name: 'NodeJS Fundamentals',
    iconUrl:
      'https://pluralsight.imgix.net/paths/path-icons/nodejs-601628d09d.png?w=60',
    id: '61f10155-59ac-479d-828d-bd0beec07e80',
    testResults: {
      category: SkillCategory.NOVICE,
      date: '2024-06-01T00:00:00.000Z' as unknown as Date,
      id: '33bdbbfe-cb8e-4eba-8f1e-858b1c9908f7',
      percentile: '14th',
      result: '60',
    },
    status: SkillStatus.COMPLETED,
    lastUpdated: '2024-06-01T00:00:00.000Z' as unknown as Date,
    url: 'https://app.pluralsight.com/skilliq/node-js/intro?context=skills',
  },
  {
    name: 'JavaScript Fundamentals',
    iconUrl:
      'https://pluralsight2.imgix.net/paths/images/javascript-542e10ea6e.png?w=60',
    id: '30282ba2-3f57-4fb0-8a4c-316742affab2',
    testResults: {
      category: SkillCategory.AVERAGE,
      date: '2025-01-04T00:00:00.000Z' as unknown as Date,
      id: '10425619-61b9-4ed1-b237-e1cb82f9766b',
      percentile: '50th',
      result: '100',
    },
    status: SkillStatus.COMPLETED,
    lastUpdated: '2025-01-04T00:00:00.000Z' as unknown as Date,
    url: 'https://app.pluralsight.com/skilliq/javascript/intro?context=skills',
  },
  {
    name: 'C# Coding Practices',
    iconUrl:
      'https://pluralsight.imgix.net/paths/path-icons/csharp-e7b8fcd4ce.png?w=60',
    id: '60d95db8-836f-420b-81e2-469a3b8802c3',
    status: SkillStatus.LIVE,
    lastUpdated: '2025-02-01T00:00:00.000Z' as unknown as Date,
    url: 'https://app.pluralsight.com/skilliq/c-sharp-coding-practices/intro?context=skills',
  },
  {
    name: 'Python for Data Analysis',
    iconUrl:
      'https://pluralsight2.imgix.net/paths/images/python-7be70baaac.png?w=60',
    id: '2a949fa8-9112-4139-9081-bfc19db42019',
    status: SkillStatus.BETA,
    lastUpdated: '2025-02-04T00:00:00.000Z' as unknown as Date,
    url: 'https://app.pluralsight.com/skilliq/python-for-data-analysis/intro?context=skills',
  },
  {
    name: 'C# Coding Practices',
    iconUrl:
      'https://pluralsight.imgix.net/paths/path-icons/csharp-e7b8fcd4ce.png?w=60',
    id: '50d95db8-836f-420b-81e2-469a3b8802c3',
    status: SkillStatus.IN_PROGRESS,
    lastUpdated: '2025-02-01T00:00:00.000Z' as unknown as Date,
    url: 'https://app.pluralsight.com/skilliq/c-sharp-coding-practices/intro?context=skills',
  },
  {
    name: 'Python for Data Analysis',
    iconUrl:
      'https://pluralsight2.imgix.net/paths/images/python-7be70baaac.png?w=60',
    id: '6a949fa8-9112-4139-9081-bfc19db42019',
    status: SkillStatus.IN_PROGRESS,
    lastUpdated: '2025-02-04T00:00:00.000Z' as unknown as Date,
    url: 'https://app.pluralsight.com/skilliq/python-for-data-analysis/intro?context=skills',
  },
  {
    name: 'C# Coding Practices',
    iconUrl:
      'https://pluralsight.imgix.net/paths/path-icons/csharp-e7b8fcd4ce.png?w=60',
    id: '70d95db8-836f-420b-81e2-469a3b8802c3',
    status: SkillStatus.LIVE,
    lastUpdated: '2025-02-01T00:00:00.000Z' as unknown as Date,
    url: 'https://app.pluralsight.com/skilliq/c-sharp-coding-practices/intro?context=skills',
  },
  {
    name: 'Python for Data Analysis',
    iconUrl:
      'https://pluralsight2.imgix.net/paths/images/python-7be70baaac.png?w=60',
    id: '8a949fa8-9112-4139-9081-bfc19db42019',
    status: SkillStatus.BETA,
    lastUpdated: '2025-02-04T00:00:00.000Z' as unknown as Date,
    url: 'https://app.pluralsight.com/skilliq/python-for-data-analysis/intro?context=skills',
  },
];
