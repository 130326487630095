// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skills-card {
  background-color: var(--pando-colors-neutral-surface-100);
  border-radius: var(--pando-radii-md);
  padding: var(--pando-spacing-6);
  margin: var(--pando-spacing-6) 0;
}
.skills-card__header {
  margin-bottom: var(--pando-spacing-3);
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 768px) {
  .skills-card__header {
    flex-direction: column;
    align-items: flex-start;
  }
}
.skills-card__header__title {
  font-size: var(--pando-font-sizes-2xl);
  font-weight: var(--pando-font-weights-semibold);
  margin-bottom: var(--pando-spacing-4);
}
.skills-card__header__button {
  height: 32px;
}
.skills-card__content {
  flex-wrap: wrap;
}
.skills-card__content .skills-widget,
.skills-card__content .skills-card__empty-slot {
  flex: 1 1 calc(25% - var(--pando-spacing-4));
  box-sizing: border-box;
}
.skills-card__content .skills-card__empty-slot {
  visibility: hidden;
  margin: var(--pando-spacing-4) var(--pando-spacing-4) 0 0;
}
@media (max-width: 768px) {
  .skills-card__content .skills-widget,
  .skills-card__content .skills-card__empty-slot {
    flex: 1 1 100%;
  }
}
.skills-card__show-more {
  margin-top: var(--pando-spacing-4);
}
.skills-card__show-more__button {
  padding: 0;
  cursor: pointer;
  color: var(--pando-colors-action-navigation-initial);
}
.skills-card__show-more__button:hover {
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/skills-card/skills-card.scss"],"names":[],"mappings":"AACA;EACE,yDAAA;EACA,oCAAA;EACA,+BAAA;EACA,gCAAA;AAAF;AAEE;EACE,qCAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;AAEI;EALF;IAMI,sBAAA;IACA,uBAAA;EACJ;AACF;AACI;EACE,sCAAA;EACA,+CAAA;EACA,qCAAA;AACN;AAEI;EACE,YAAA;AAAN;AAIE;EACE,eAAA;AAFJ;AAII;;EAEE,4CAAA;EACA,sBAAA;AAFN;AAKI;EACE,kBAAA;EACA,yDAAA;AAHN;AAMI;EACE;;IAEE,cAAA;EAJN;AACF;AAQE;EACE,kCAAA;AANJ;AAOI;EACE,UAAA;EACA,eAAA;EACA,oDAAA;AALN;AAMM;EACE,6BAAA;AAJR","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.skills-card {\n  background-color: var(--pando-colors-neutral-surface-100);\n  border-radius: var(--pando-radii-md);\n  padding: var(--pando-spacing-6);\n  margin: var(--pando-spacing-6) 0;\n\n  &__header {\n    margin-bottom: var(--pando-spacing-3);\n    justify-content: space-between;\n    align-items: center;\n\n    @media (max-width: 768px) {\n      flex-direction: column;\n      align-items: flex-start;\n    }\n\n    &__title {\n      font-size: var(--pando-font-sizes-2xl);\n      font-weight: var(--pando-font-weights-semibold);\n      margin-bottom: var(--pando-spacing-4);\n    }\n\n    &__button {\n      height: 32px;\n    }\n  }\n\n  &__content {\n    flex-wrap: wrap;\n\n    .skills-widget,\n    .skills-card__empty-slot {\n      flex: 1 1 calc(25% - var(--pando-spacing-4));\n      box-sizing: border-box;\n    }\n\n    .skills-card__empty-slot {\n      visibility: hidden;\n      margin: var(--pando-spacing-4) var(--pando-spacing-4) 0 0;\n    }\n\n    @media (max-width: 768px) {\n      .skills-widget,\n      .skills-card__empty-slot {\n        flex: 1 1 100%;\n      }\n    }\n  }\n\n  &__show-more {\n    margin-top: var(--pando-spacing-4);\n    &__button {\n      padding: 0;\n      cursor: pointer;\n      color: var(--pando-colors-action-navigation-initial);\n      &:hover {\n        background-color: transparent;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
