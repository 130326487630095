// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-card {
  background-color: var(--pando-colors-neutral-surface-100);
  border-radius: var(--pando-radii-md);
  padding: var(--pando-spacing-6);
}`, "",{"version":3,"sources":["webpack://./src/features/profile/components/profile-card/profile-card.scss"],"names":[],"mappings":"AACA;EACE,yDAAA;EACA,oCAAA;EACA,+BAAA;AAAF","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.profile-card {\n  background-color: var(--pando-colors-neutral-surface-100);\n  border-radius: var(--pando-radii-md);\n  padding: var(--pando-spacing-6);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
