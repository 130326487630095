import { FlexContainer } from '@pluralsight/react-ng';

import CertificationItem from '../certification-item/certification-item';

import type { Certification } from '../../state/interfaces';

const CertificationList = ({
  certifications = [],
  onEdit,
}: {
  certifications: Certification[];
  onEdit: (certificate: Certification) => void;
}) => {
  const certificationList = certifications.map(
    (certification: Certification) => (
      <CertificationItem
        key={certification.id}
        certification={certification}
        onEdit={onEdit}
      />
    ),
  );

  return <FlexContainer direction="col">{certificationList}</FlexContainer>;
};

export default CertificationList;
