import React, { useEffect, useState, useMemo } from 'react';
import './skills-card.scss';
import { Button, FlexContainer, For } from '@pluralsight/react-ng';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import SkillsPlaceHolder from '../skills-placeholder/skills-placeholder';
import SkillsWidget from '../skills-widget/skills-widget';
import { selectUserSkills } from '../../state/selectors';
import { fetchUserSkills } from '../../state/slice';

import type { Skill } from '../../state/interfaces';

const MAX_SKILLS_PER_LINE = 4;

const SkillsCard: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const skills = useSelector(selectUserSkills);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    dispatch(fetchUserSkills());
  }, [dispatch]);

  const displayedSkills = useMemo(() => {
    return showAll
      ? (skills ?? [])
      : (skills?.slice(0, MAX_SKILLS_PER_LINE) ?? []);
  }, [showAll, skills]);

  const hasMoreSkills = skills && skills.length > MAX_SKILLS_PER_LINE;

  const renderEmptySlots = () => {
    const remainingSlots =
      MAX_SKILLS_PER_LINE - (displayedSkills.length % MAX_SKILLS_PER_LINE);

    if (remainingSlots === MAX_SKILLS_PER_LINE) return null;

    return Array.from({ length: remainingSlots }).map((_, index) => (
      <div className="skills-card__empty-slot" key={`empty-${index}`} />
    ));
  };

  const renderShowMoreButton = () => {
    if (!hasMoreSkills) return null;

    const showMoreText = showAll
      ? t('profile.skillsWidget.showLess')
      : t('profile.skillsWidget.showMore');

    return (
      <FlexContainer className="skills-card__show-more">
        <Button
          className="skills-card__show-more__button"
          usage="text"
          onClick={() => setShowAll(!showAll)}
          aria-label={showMoreText}
        >
          {showMoreText}
        </Button>
      </FlexContainer>
    );
  };

  const redirectToSkills = () => {
    window.location.href = 'https://app.pluralsight.com/skilliq/discover';
  };

  return (
    <FlexContainer className="skills-card" direction="col">
      <FlexContainer className="skills-card__header">
        <h3 className="skills-card__header__title">
          {t('profile.skillsWidget.skillsIq')}
        </h3>
        {skills && skills.length > 0 ? (
          <Button
            className="skills-card__header__button"
            palette="action"
            onClick={redirectToSkills}
          >
            {t('profile.skillsWidget.button')}
          </Button>
        ) : null}
      </FlexContainer>
      <FlexContainer
        className="skills-card__content"
        direction="row"
        wrap="wrap"
      >
        <For
          each={displayedSkills}
          fallback={<SkillsPlaceHolder />}
          children={(skill: Skill, index: number): JSX.Element => (
            <SkillsWidget skill={skill} key={index} />
          )}
        />
        {renderEmptySlots()}
      </FlexContainer>
      {renderShowMoreButton()}
    </FlexContainer>
  );
};

export default SkillsCard;
