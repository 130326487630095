// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.personal-details__username {
  margin: 0 var(--pando-spacing-4);
}
.personal-details__pencil-icon {
  cursor: pointer;
}
.personal-details__form {
  padding-top: var(--pando-spacing-8);
}
.personal-details__label {
  font-size: 14px;
  font-weight: 700;
  line-height: 150%;
}
.personal-details__input {
  margin-bottom: var(--pando-spacing-6);
  max-width: 700px;
}
.personal-details__button {
  cursor: pointer;
}
.personal-details__spacing {
  width: var(--pando-spacing-4);
}`, "",{"version":3,"sources":["webpack://./src/features/settings/components/personal-details/personal-details.scss"],"names":[],"mappings":"AAEE;EACE,gCAAA;AADJ;AAIE;EACE,eAAA;AAFJ;AAKE;EACE,mCAAA;AAHJ;AAME;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AAJJ;AAOE;EACE,qCAAA;EACA,gBAAA;AALJ;AAQE;EACE,eAAA;AANJ;AASE;EACE,6BAAA;AAPJ","sourcesContent":["@use '@pluralsight/design-tokens/variables.scss' as *;\n.personal-details {\n  &__username {\n    margin: 0 var(--pando-spacing-4);\n  }\n\n  &__pencil-icon {\n    cursor: pointer;\n  }\n\n  &__form {\n    padding-top: var(--pando-spacing-8);\n  }\n\n  &__label {\n    font-size: 14px;\n    font-weight: 700;\n    line-height: 150%;\n  }\n\n  &__input {\n    margin-bottom: var(--pando-spacing-6);\n    max-width: 700px;\n  }\n\n  &__button {\n    cursor: pointer;\n  }\n\n  &__spacing {\n    width: var(--pando-spacing-4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
