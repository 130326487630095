import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { selectUser } from '../../user/state/selectors';

import { NotificationsService } from './services';
import {
  deleteNotification,
  deleteNotificationFailure,
  deleteNotificationSuccess,
  fetchNotifications,
  fetchNotificationsFailure,
  fetchNotificationsSuccess,
  readNotifications,
  readNotificationsFailure,
  readNotificationsSuccess,
} from './slice';

import type { Notification, NotificationsMessage } from './interfaces';
import type { AxiosInstance } from 'axios';
import type { PayloadAction } from '@reduxjs/toolkit';

function* fetchNotificationsSaga() {
  try {
    const httpClient: AxiosInstance | null = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('httpClient is not available in saga context');
    }

    const notificationService = new NotificationsService(httpClient);

    const { id } = yield select(selectUser);

    if (!id) throw new Error('No user id provided');

    const response: Notification[] = yield call(
      notificationService.getNotifications,
      id,
    );

    yield put(fetchNotificationsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(fetchNotificationsFailure(error.message));
    } else {
      yield put(fetchNotificationsFailure('An unknown error occurred'));
    }
  }
}

function* readNotificationsSaga(
  action: PayloadAction<Notification[], string, { extra: AxiosInstance }>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('httpClient is not available in saga context');
    }

    const notificationService = new NotificationsService(httpClient);

    const { id } = yield select(selectUser);

    if (!id) throw new Error('No id provided');

    const response: NotificationsMessage = yield call(
      notificationService.readNotifications,
      action.payload,
      id,
    );

    yield put(readNotificationsSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(readNotificationsFailure(error.message));
    } else {
      yield put(readNotificationsFailure('An unknown error occurred'));
    }
  }
}

function* deleteNotificationSaga(
  action: PayloadAction<string, string, { extra: AxiosInstance }>,
) {
  try {
    const httpClient: AxiosInstance = yield getContext('httpClient');

    if (!httpClient) {
      throw new Error('httpClient is not available in saga context');
    }

    const notificationService = new NotificationsService(httpClient);

    const { id } = yield select(selectUser);

    if (!id) throw new Error('No id provided');

    const response: NotificationsMessage = yield call(
      notificationService.deleteNotification,
      action.payload,
      id,
    );

    yield put(deleteNotificationSuccess(response));
  } catch (error) {
    if (error instanceof Error) {
      yield put(deleteNotificationFailure(error.message));
    } else {
      yield put(deleteNotificationFailure('An unknown error occurred'));
    }
  }
}

export default function* notificationsSagas() {
  yield takeLatest(fetchNotifications.type, fetchNotificationsSaga);
  yield takeLatest(readNotifications.type, readNotificationsSaga);
  yield takeLatest(deleteNotification.type, deleteNotificationSaga);
}
