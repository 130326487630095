import { useEffect, useState } from 'react';
import { CalendarIcon, TrashIcon } from '@pluralsight/icons';
import {
  Button,
  FieldMessage,
  FlexContainer,
  FlexItem,
  FormControlProvider,
  Input,
  Label,
  useAutoFormatDate,
  useConfirm,
  useFileUpload,
} from '@pluralsight/react-ng';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CustomSelect from '../../../../components/custom-select/custom-select';
import { SelectCertificationProviders } from '../../state/selectors';
import {
  deleteCertification,
  patchUserCertification,
  postUserCertification,
} from '../../state/slice';
import UploadCertificationField from '../upload-certification-field/upload-certification-field';
import useParsedDate from '../../hooks/use-parsed-date';
import { useFileChangeHandler } from '../../hooks/useFileChangeHandler';

import { isCertificationDateValid } from './certification-form-validations';

import type { OptionType } from '../../../../components/custom-select/custom-select';
import type { Certification } from '../../state/interfaces';
import type { MouseEvent } from 'react';

import './certification-form.scss';

const fileUploadConfig = {
  accept: { 'application/*': ['.pdf'] },
  maxFiles: 1,
  maxFileSize: 1024 * 1024 * 4, // 4MB
};

const initialFormState = {
  providerName: { invalid: false, required: true, isTouched: false },
  certificationId: { invalid: false, required: true, isTouched: false },
  achievedDate: { invalid: false, required: true, isTouched: false },
  expirationDate: { invalid: false, required: false, isTouched: false },
  validationCode: { invalid: false, required: true, isTouched: false },
  certificationFile: { invalid: false, required: true, isTouched: false },
};

export type formStateType = typeof initialFormState;

type InitialFormStateKeys = keyof typeof initialFormState;

const initialFormFields: Certification = {
  id: null,
  providerName: '',
  certificationName: '',
  certificationId: '',
  achievedDate: '',
  expirationDate: '',
  validationCode: '',
  certificationFile: null,
  objectKey: null,
  fileId: null,
};

type OptionWithCertification = OptionType & { certifications: OptionType[] };

const CertificationForm = ({
  initialFormValue,
  onCancel,
}: {
  initialFormValue: Certification | null;
  onCancel: () => void;
}) => {
  const [formState, setFormState] = useState(initialFormState);
  const [isFormValid, setIsFormValid] = useState(false);
  const [providerOptions, setProviderOptions] = useState<
    OptionWithCertification[]
  >([]);
  const [certificationOptions, setCertificationOptions] = useState<
    OptionType[]
  >([]);
  const [formFields, setFormFields] = useState<Certification>(
    initialFormValue || initialFormFields,
  );
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const { formatToDefaultDate } = useParsedDate();
  const dispatch = useDispatch();
  const providers = useSelector(SelectCertificationProviders);

  const formatDateAchieved = useAutoFormatDate({
    value: initialFormValue?.achievedDate,
  });

  const formatDateExpired = useAutoFormatDate({
    value: initialFormValue?.expirationDate,
  });

  const clearRejectedFiles = () => {
    fileUpload.value.clearRejectedFiles();
  };
  const { handleFileChange } = useFileChangeHandler(clearRejectedFiles);

  const fileUpload = {
    name: 'certificationFile',
    value: useFileUpload({
      ...fileUploadConfig,
      onFileChange: handleFileChange,
    }),
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    e.preventDefault();

    const { name, value } = e.target;

    handleFormChange(name, value, true);
  };

  const handleCertificationChange = ({
    name,
    option,
  }: {
    name: string;
    option: OptionType;
  }) => {
    handleFormChange(name, option.value);

    setFormFields((prev) => ({
      ...prev,
      certificationName: option.label,
    }));
  };

  const handleProviderChange = ({
    name,
    option,
  }: {
    name: string;
    option: OptionType;
  }) => {
    handleFormChange(name, option.label);

    if (option.label !== formFields.providerName) {
      handleFormChange('certificationId', '');
    }

    const options = providerOptions.find((el) => el.value === option.value);

    setCertificationOptions(options?.certifications as OptionType[]);
  };

  const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    const value = {
      ...formFields,
      achievedDate: formatToDefaultDate(formFields.achievedDate),
      expirationDate: formatToDefaultDate(formFields.expirationDate),
    };

    if (initialFormValue) {
      dispatch(patchUserCertification({ value }));
    } else {
      dispatch(postUserCertification({ value }));
    }

    onCancel();
  };

  const onDeleteCertificate = async () => {
    const confirmation = await confirm({
      heading: t('certifications.certificationForm.deleteThisCertificate'),
      text: t('certifications.certificationForm.areYouSureToDelete'),
      confirmText: t('certifications.certificationForm.deleteCertificate'),
      kind: 'destructive',
    });

    if (confirmation) {
      dispatch(
        deleteCertification({
          id: initialFormValue?.id as string,
        }),
      );
      onCancel();
    }
  };

  const checkFormValidity = () => {
    const hasInvalidFields = Object.values(formState).some(
      (field) => field.invalid,
    );

    if (hasInvalidFields) return false;

    const validity = Object.keys(formState)
      .filter((key: string) => formState[key as InitialFormStateKeys].required)
      .every((key: string) => {
        const value = formFields[key as keyof typeof initialFormFields];

        return Array.isArray(value)
          ? (value && value.length) || formFields.objectKey
          : !!value;
      });

    return validity;
  };

  const handleFormChange = (
    name: string,
    value: string,
    markTouched?: boolean,
  ) => {
    updateFormValue(name, value);
    updateFormStatus(name, value, markTouched);
  };

  const updateFormValue = (name: string, value: string) =>
    setFormFields((prev) => ({
      ...prev,
      [name]: value,
    }));

  const updateFormStatus = (
    name: string,
    value: string,
    markTouched?: boolean,
  ) => {
    const fieldName = name as keyof typeof formState;

    setFormState((prev) => ({
      ...prev,
      [name]: {
        ...prev[fieldName],
        invalid: setIsInvalid(fieldName, value, markTouched),
      },
    }));
  };

  useEffect(() => {
    if (formState.achievedDate.isTouched) {
      updateFormStatus('achievedDate', formFields.achievedDate || '', true);
    }

    if (formState.expirationDate.isTouched) {
      updateFormStatus('expirationDate', formFields.expirationDate || '', true);
    }
  }, [
    formFields.achievedDate,
    formFields.expirationDate,
    formState.achievedDate.isTouched,
    formState.expirationDate.isTouched,
  ]);

  const setIsInvalid = (
    fieldName: keyof typeof formState,
    value: string,
    markTouched?: boolean,
  ): boolean => {
    if (
      formState[fieldName].required &&
      (markTouched || formState[fieldName].isTouched)
    ) {
      if (Array.isArray(value)) {
        return !value.length;
      } else if (['achievedDate', 'expirationDate'].includes(fieldName)) {
        return !isCertificationDateValid(fieldName, value, formFields);
      }

      return value.trim() === '';
    }

    if (
      (markTouched || formState[fieldName].isTouched) &&
      ['expirationDate'].includes(fieldName) &&
      value.trim() !== ''
    ) {
      return !isCertificationDateValid(fieldName, value, formFields);
    }

    return false;
  };

  const onFileDelete = () => {
    setFormState((prev) => ({
      ...prev,
      certificationFile: {
        ...prev['certificationFile'],
        isTouched: true,
      },
    }));

    clearRejectedFiles();

    if (initialFormValue?.objectKey) {
      updateFormValue('objectKey', '');
    }
  };

  const formAction = () =>
    initialFormValue
      ? t('certifications.certificationForm.updateCertificate')
      : t('certifications.certificationForm.addCertificate');

  useEffect(() => {
    setIsFormValid(checkFormValidity());
  }, [formFields]);

  useEffect(() => {
    if (fileUpload.value.acceptedFiles) {
      handleFormChange(fileUpload.name, fileUpload.value.acceptedFiles);
    }
  }, [fileUpload.value.acceptedFiles, fileUpload.value.rejectedFiles]);

  useEffect(() => {
    handleFormChange('achievedDate', formatDateAchieved.value);

    if (formatDateAchieved.value) {
      setFormState((prev) => ({
        ...prev,
        achievedDate: {
          ...prev['achievedDate'],
          isTouched: true,
        },
      }));
    }
  }, [formatDateAchieved.value, formState.achievedDate.isTouched]);

  useEffect(() => {
    handleFormChange('expirationDate', formatDateExpired.value);

    if (formatDateExpired.value) {
      setFormState((prev) => ({
        ...prev,
        expirationDate: {
          ...prev['expirationDate'],
          isTouched: true,
          invalid: !isCertificationDateValid(
            'expirationDate',
            formatDateExpired.value,
            formFields,
          ),
        },
      }));
    }
  }, [formatDateExpired.value]);

  useEffect(() => {
    const mappedProviders = providers.map((el) => ({
      label: el.providerName,
      value: el.providerId,
      certifications: el.certifications.map((el) => ({
        label: el.name,
        value: el.id,
      })),
    }));

    setProviderOptions(mappedProviders);
  }, providers);

  return (
    <FlexContainer className="certification-container" direction="col">
      <h3 className="certification-container__title">
        {t('certifications.certificationForm.uploadCertificate')}
      </h3>
      <form onSubmit={(e) => e.preventDefault()}>
        <FlexContainer className="certification-container-form" direction="col">
          <FlexItem className="certification-container-form__field">
            <FormControlProvider {...formState.providerName}>
              <CustomSelect
                id="providerName"
                label={t('certifications.certificationForm.provider')}
                name="providerName"
                placeholder={t('certifications.certificationForm.selectOne')}
                initialValue={providerOptions.find(
                  (el) => el.label === formFields.providerName,
                )}
                options={providerOptions}
                onValueUpdate={handleProviderChange}
                errorMsg={t('certifications.certificationForm.provideRequired')}
              />
            </FormControlProvider>
          </FlexItem>
          <FlexItem className="certification-container-form__field">
            <FormControlProvider {...formState.certificationId}>
              <CustomSelect
                id="certificationId"
                label={t('certifications.certificationForm.certification')}
                name="certificationId"
                placeholder={t('certifications.certificationForm.selectOne')}
                initialValue={certificationOptions.find(
                  (el) => el.value === formFields.certificationId,
                )}
                options={certificationOptions}
                onValueUpdate={handleCertificationChange}
                errorMsg={t(
                  'certifications.certificationForm.certificationRequired',
                )}
              />
            </FormControlProvider>
          </FlexItem>
          <FlexContainer
            className="certification-container-form__dates"
            gap={10}
          >
            <FlexItem className="certification-container-form__dates__field">
              <FormControlProvider invalid={formState.achievedDate.invalid}>
                <Label
                  className={`certification-container-form__dates__field__label${formState.achievedDate.invalid ? ' certification-container-form__dates__field__label--error' : ''}`}
                  htmlFor="achievedDate"
                >
                  {t('certifications.certificationForm.dateAchieved')}
                </Label>
                <Input
                  {...(formState.achievedDate.invalid && {
                    'aria-invalid': 'true',
                  })}
                  {...formatDateAchieved}
                  className="certification-container-form__dates__field__input"
                  id="achievedDate"
                  name="achievedDate"
                  startIcon={<CalendarIcon />}
                />
                {formState.achievedDate.invalid && (
                  <FieldMessage
                    id="achievedDate:invalid"
                    className="certification-container-form__dates__field--error"
                  >
                    {t('certifications.certificationForm.selectValidDate')}
                  </FieldMessage>
                )}
              </FormControlProvider>
            </FlexItem>
            <FlexItem className="certification-container-form__dates__field">
              <FormControlProvider invalid={formState.expirationDate.invalid}>
                <Label
                  className={`certification-container-form__dates__field__label${formState.expirationDate.invalid ? ' certification-container-form__dates__field__label--error' : ''}`}
                  htmlFor="expirationDate"
                >
                  {t('certifications.certificationForm.dateExpired')}
                </Label>
                <Input
                  {...formatDateExpired}
                  className="certification-container-form__dates__field__input"
                  id="expirationDate"
                  name="expirationDate"
                  startIcon={<CalendarIcon />}
                />
                {formState.expirationDate.invalid && (
                  <FieldMessage
                    id="expirationDate:invalid"
                    className="certification-container-form__dates__field--error"
                  >
                    {t('certifications.certificationForm.selectValidDate')}
                  </FieldMessage>
                )}
              </FormControlProvider>
            </FlexItem>
          </FlexContainer>
          <FlexItem className="certification-container-form__field">
            <FormControlProvider invalid={formState.validationCode.invalid}>
              <Label
                className={`certification-container-form__field__label${formState.validationCode.invalid ? ' certification-container-form__field__label--error' : ''}`}
                htmlFor="validationCode"
              >
                {t('certifications.certificationForm.validationCode')}
              </Label>
              <Input
                className="certification-form__field__input"
                id="validationCode"
                name="validationCode"
                placeholder={t('certifications.certificationForm.enterCode')}
                size="lg"
                value={formFields.validationCode}
                onChange={handleInputChange}
              />
              {formState.validationCode.invalid && (
                <FieldMessage
                  id="validationCode:invalid"
                  className="certification-container-form__dates__field--error"
                >
                  {t('certifications.certificationForm.validationCodeRequired')}
                </FieldMessage>
              )}
            </FormControlProvider>
          </FlexItem>
          <UploadCertificationField
            objectKey={formFields?.objectKey}
            formState={formState}
            fileUpload={fileUpload}
            onFileDelete={onFileDelete}
          />
          <FlexContainer
            className="certification-container-form__actions"
            align="center"
            justify="spaceBetween"
          >
            <FlexContainer gap={15}>
              <Button
                size="lg"
                palette="action"
                type="button"
                usage="outline"
                aria-label={t(
                  'certifications.certificationForm.cancelAddCertificate',
                )}
                onClick={onCancel}
              >
                {t('certifications.certificationForm.cancel')}
              </Button>
              <Button
                size="lg"
                type="submit"
                palette="action"
                usage="filled"
                aria-label={formAction()}
                onClick={onSubmit}
                disabled={!isFormValid}
              >
                {formAction()}
              </Button>
            </FlexContainer>
            {initialFormValue && (
              <FlexContainer justify="end">
                <Button
                  size="lg"
                  type="button"
                  className="certification-container-form__actions__delete"
                  palette="action"
                  usage="text"
                  aria-label={t(
                    'certifications.certificationForm.deleteCertificate',
                  )}
                  startIcon={<TrashIcon />}
                  onClick={() => void onDeleteCertificate()}
                >
                  {t('certifications.certificationForm.deleteCertificate')}
                </Button>
              </FlexContainer>
            )}
          </FlexContainer>
        </FlexContainer>
      </form>
    </FlexContainer>
  );
};

export default CertificationForm;
